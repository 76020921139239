import { Component } from '@angular/core'
import { EvidaEvents } from 'src/app/tracking/evida-events'
import { TrackEvent } from 'src/app/tracking/google-analytics'
import { RedirectTo } from 'src/app/utils/redirect-to'

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found-component.html',
  styleUrls: ['./not-found-component.scss'],
  standalone: false
})
export class NotFoundComponent {

  @TrackEvent(EvidaEvents.NotFound.clicks.backToHomepage)
  public backToHomepage() {
    RedirectTo.loginLandingPage()
  }
}
