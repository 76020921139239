import { Component } from '@angular/core'

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  standalone: false
})
export class MaintenanceComponent {

}
