<mat-accordion class="accordion" [displayMode]="'flat'" hideToggle="true">
  <mat-expansion-panel #accordion>
    <mat-expansion-panel-header
      [collapsedHeight]="'30px'"
      [expandedHeight]="'30px'"
    >
      <div class="flex">
        <mat-icon
          [ngClass]="{ rotate: accordion.expanded }"
          svgIcon="evida_plus"
          inline="true"
        ></mat-icon>
        <mat-panel-title
          class="body-copy ml-10px"
          translate="userProfile.customerRelations.title"
        >
        </mat-panel-title>
      </div>
    </mat-expansion-panel-header>

    <div class="flex flex-col">
      <span
        *ngIf="!isBusiness"
        class="mb-30px"
        translate="userProfile.customerRelations.header"
      ></span>
      <span
        *ngIf="isBusiness"
        class="mb-30px"
        translate="userProfile.customerRelations.businessHeader"
      ></span>
      <app-evida-button
        class="button mb-10px"
        type="primary"
        (clicked)="onAddCustomerRelation()"
      >
        {{ "userProfile.customerRelations.primaryButton" | translate }}
      </app-evida-button>
    </div>
  </mat-expansion-panel>
</mat-accordion>
