<app-loader *ngIf="loading$ | async"></app-loader>
<div class="flex flex-col">
    <h1 class="mb-30px header-medium" [translate]="data.title"></h1>
    <div class="flex flex-col">
        <form [formGroup]="formGroup">
            <div class="flex flex-col">
                <!-- Email -->
                <app-form-input class="flex flex-col" title="userProfile.associatedUsers.email"
                    placeholder="userProfile.associatedUsers.emailPlaceholder" [errorLookup]="{
                         pattern: 'shared.errormessages.emailInvalid' | translate,
                         incorrect: 'shared.errormessages.emailInvalid' | translate
                        }" [control]="formGroup.controls.email">
                </app-form-input>
                <span *ngIf="!data.primaryEmail" class="body-copy info" translate="userProfile.paymentDetails.businessInvoice.invoice.info"></span>
            </div>
        </form>
    </div>

    <div class="flex justify-between mt-30px">
        <app-evida-button class="button" type="secondary" (clicked)="cancel()">
            {{ 'userProfile.paymentDetails.businessInvoice.cancelButton' | translate }}
        </app-evida-button>
        <app-evida-button class="button" type="primary" (clicked)="submit()" [disabled]="!formGroup.valid">
            {{ submitButtonTitle | translate }}
        </app-evida-button>
    </div>
</div>