import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { cprValidator } from '../../../../shared/utilities/cpr.validator'

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss'],
  standalone: false
})
export class ConsentComponent {
  @Input() public consentLabel?: string
  @Input() public birthDate?: string
  @Output() public consentAccepted = new EventEmitter<string | null>()

  public formGroup = new UntypedFormGroup({
    cpr: new UntypedFormControl('', [Validators.required, cprValidator()]),
    consent: new UntypedFormControl('', [Validators.requiredTrue])
  })

  public onlyConsentFormGroup = new UntypedFormGroup({
    consent: new UntypedFormControl('', [Validators.requiredTrue])
  })

  public onSubmit() {
    if (this.birthDate) {
      this.consentAccepted.emit(null)
    } else {
      this.consentAccepted.emit(this.formGroup.controls.cpr.value)
    }
  }

  public handleCprErrorLabel() {
    if (this.formGroup.controls.cpr.invalid) {
      this.formGroup.controls.cpr.setErrors({ incorrect: true })
    } else {
      this.formGroup.controls.cpr.setErrors({ incorrect: false })
      this.formGroup.controls.cpr.updateValueAndValidity()
    }
  }
}
