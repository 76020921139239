import { Directive, ElementRef, OnInit } from '@angular/core'
@Directive({
  selector: '[appRequired]',
  standalone: false
})
export class RequiredDirective implements OnInit {
  constructor(
    private elRef: ElementRef
  ) { }
  ngOnInit(): void {
    const oldHtml = this.elRef.nativeElement.innerHTML
    this.elRef.nativeElement.innerHTML = `${oldHtml}<span class="required">*</span>`
  }
}
