import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Input,
} from '@angular/core'
import {
  BehaviorSubject,
  Observable,
  Subscription,
  firstValueFrom,
  of,
} from 'rxjs'
import { AuthService } from 'src/app/shared/auth/auth.service'
import { MenuItemId } from 'src/app/shared/models/menu.model'
import {
  EanStatus,
  ProfileInstallation,
  ProfileListItem,
  UpdateInvoiceViaEmailRequest,
} from 'src/app/shared/services/backend'
import { MenuService } from 'src/app/shared/services/menu.service'
import { ProfileLoginService } from 'src/app/shared/services/profile-login.service'
import { Utils } from 'src/app/utils/utils'
import { AssociatedUsers } from '../../models/associated-users.model'
import { InstallationDetails } from '../../models/installation-details.model'
import { InstallationItem } from '../../models/installation-item.model'
import { PaymentDetails } from '../../models/payment-details.model'
import { BusinessProfileService } from '../../services/business-profile.service'

@Component({
  selector: 'app-business-profile',
  templateUrl: './business-profile.component.html',
  styleUrls: ['./business-profile.component.scss'],
  standalone: false
})
export class BusinessProfileComponent
implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy
{
  @Input() isInToolbar: boolean = false

  private subscription: Subscription = new Subscription()
  private installations = new BehaviorSubject<Array<ProfileListItem> | null>(
    null,
  )
  private associatedUsers = new BehaviorSubject<AssociatedUsers>(null)
  private paymentDetails = new BehaviorSubject<PaymentDetails>(null)
  private installationDetails = new BehaviorSubject<InstallationDetails>(null)
  private supplierName = new BehaviorSubject<string | null>(null)
  private customerNumber = new BehaviorSubject<string>(null)
  private selectedInstallation: InstallationItem | null = null
  private eanStatus = new BehaviorSubject<EanStatus | null>(null)

  public installations$ = this.installations.asObservable()
  public associatedUsers$ = this.associatedUsers.asObservable()
  public paymentDetails$ = this.paymentDetails.asObservable()
  public installationDetails$ = this.installationDetails.asObservable()
  public supplierName$ = this.supplierName.asObservable()
  public customerNumber$ = this.customerNumber.asObservable()
  public loading$: Observable<boolean> = of(true)
  public eanStatus$ = this.eanStatus.asObservable()

  constructor(
    private profileService: BusinessProfileService,
    private profileLoginService: ProfileLoginService,
    private changeDetectorRef: ChangeDetectorRef,
    public menuService: MenuService,
    public authService: AuthService,
  ) {}

  public ngOnInit() {
    if (!this.isInToolbar) {
      this.menuService.preselectedMenuItem(MenuItemId.profile)
    }
    this.getInstallations()
    this.getEanStatus()
  }

  public ngAfterViewInit(): void {
    Utils.scrollToTop()
  }

  public ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges()
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  public async onSelectInstallation(installationItem: InstallationItem) {
    // Set customer number and installtion number for selected installation
    this.loading$ = of(true)
    await this.profileService.setSelectedInstallation({
      customerNumber: installationItem.customerNumber,
      installationNumber:
        installationItem.selectedInstallation.installationNumber,
    })
    this.selectedInstallation = installationItem
    this.getAssociatedUsersInformation(
      installationItem.customerNumber,
      installationItem.selectedInstallation,
    )
  }

  public onAddCustomerRelation() {
    this.profileLoginService.addCustomerRelation()
  }

  public onSubscribePaymentService(url: string) {
    // Open payment service URL in new tab
    window.open(url, '_blank')
  }

  public reload() {
    window.location.reload()
  }

  public onUpdateInvoiceEmail(email: string) {
    this.loading$ = of(true)
    const request: UpdateInvoiceViaEmailRequest = {
      invoiceViaEmailActive: !email || email?.length === 0 ? false : true,
      email: email?.length > 0 ? email : '',
    }
    this.profileService
      .updateInvoiceEmail(this.selectedInstallation.customerNumber, request)
      .subscribe((response) => {
        if (response) {
          this.onUpdateModelAndUi()
        }
      })
  }

  public onUpdateEanInvoice() {
    this.getEanStatus()
  }

  public onUpdateModelAndUi() {
    this.onSelectInstallation(this.selectedInstallation)
    this.getEanStatus()
  }

  private async getInstallations() {
    this.loading$ = of(true)
    const installations = await firstValueFrom(
      this.profileService.getInstallations(),
    )
    this.installations.next(installations)
    this.loading$ = of(false)
  }

  private async getEanStatus() {
    this.subscription.add(
      this.customerNumber.subscribe(async (customerNumber) => {
        if (customerNumber) {
          const eanStatus = await firstValueFrom(
            this.profileService.getEanStatus(customerNumber),
          )
          if (eanStatus) {
            this.eanStatus.next(eanStatus.status)
          }
        }
      }),
    )
  }

  private getAssociatedUsersInformation(
    customerNumber: string,
    installation: ProfileInstallation,
  ) {
    this.subscription.add(
      this.profileService
        .getAssociatedUsersInformation(
          customerNumber,
          installation.installationNumber,
        )
        .subscribe((response) => {
          if (response) {
            this.customerNumber.next(customerNumber)
            this.associatedUsers.next({
              secondaryName: response.associatedUsers.secondaryName,
              primary: {
                ...response.associatedUsers.primary,
                invoiceViaEmail: response.invoiceViaEmailEmail,
                isBusiness: true,
              },
            })
            this.paymentDetails.next({
              paymentServiceActive: response.paymentServiceActive,
              paymentServiceUrl: response.paymentServiceUrl,
              hasInvoiceViaEmail: response.hasInvoiceViaEmail,
              invoiceViaEmail: response.invoiceViaEmailEmail,
              primaryEmail: response.associatedUsers.primary.email,
              hasEanNumber: response.eanNumber ? true : false,
              eanNumber: response.eanNumber,
              customerNumber,
              isBusiness: true,
            })
            this.installationDetails.next({
              meterNumber: response.meterNumber,
              gasSupplyStartDate: response.gasSupplyStartDate,
              meteringPoint: response.meteringPoint,
              supplierName: response.supplierName,
              correctorNumber: response.correctorNumber,
              installationNumber: installation.installationNumber,
              displayReadingPlan: installation.displayReadingPlan,
            })
            this.supplierName.next(response.supplierName)
          }
          this.loading$ = of(false)
        }),
    )
  }
}
