import { Component, EventEmitter, Input, Output } from '@angular/core'
import { DialogService } from 'src/app/shared/services/dialog.service'
import { AssociatedUsers } from '../../../models/associated-users.model'
import { EditUserUtils } from '../../../utils/edit-user.utils'
import { EditBusinessUserComponent } from '../edit-user/edit-business-user.component'
import { SyncroniseEmailComponent } from '../syncronise-email/syncronise-email.component'

@Component({
  selector: 'app-business-associated-users',
  templateUrl: './business-associated-users.component.html',
  standalone: false
})
export class BusinessAssociatedUsersComponent {
  @Input() public associatedUsers: AssociatedUsers
  @Input() public customerNumber: string
  @Output() public updateInvoiceEmail = new EventEmitter<string>()
  @Output() public updateModelAndUi = new EventEmitter()

  constructor(private dialogService: DialogService) { }

  public onEditUserForm(user: AssociatedUsers) {
    const dialogRef = this.dialogService.openProfileComponent({
      data: { ...user, customerNumber: this.customerNumber },
      component: EditBusinessUserComponent
    })

    dialogRef.afterClosed().subscribe(response => {
      // Update UI after successful backend call
      if (response) {
        /* eslint-disable max-len */
        this.associatedUsers.primary.cellPhonePrefix = EditUserUtils.formatInputValue(this.associatedUsers.primary.cellPhonePrefix, response.cellPhoneCountryCode)
        this.associatedUsers.primary.formattedCellPhoneNumber = EditUserUtils.formatInputValue(this.associatedUsers.primary.formattedCellPhoneNumber, response.cellPhoneNumber)
        this.associatedUsers.primary.phonePrefix = EditUserUtils.formatInputValue(this.associatedUsers.primary.phonePrefix, response.phoneCountryCode)
        this.associatedUsers.primary.formattedPhoneNumber = EditUserUtils.formatInputValue(this.associatedUsers.primary.formattedPhoneNumber, response.phoneNumber)

        // Show new dialog depending on primary email changes
        const category = EditUserUtils.getSyncroniseEmailDialog(this.associatedUsers, response.email)
        this.associatedUsers.primary.email = EditUserUtils.formatInputValue(this.associatedUsers.primary.email, response.email)

        if (category) {
          this.openSyncroniseEmailDialog(category)
        } else {
          this.updateModelAndUi.emit()
        }
      }
    })
  }

  private openSyncroniseEmailDialog(category: string) {
    const titleKey = `userProfile.paymentDetails.syncroniseEmail.${category}.title`
    const subTitleKey = `userProfile.paymentDetails.syncroniseEmail.${category}.subTitle`

    const dialogRef = this.dialogService.openProfileComponent({
      data: {
        title: titleKey,
        subtitle: subTitleKey,
        primaryEmail: this.associatedUsers.primary.email,
        invoiceEmail: this.associatedUsers.primary.invoiceViaEmail
      },
      component: SyncroniseEmailComponent
    })
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.updateInvoiceEmail.emit(this.associatedUsers.primary.email)
      }
    })
  }
}
