import { Component, EventEmitter, Input, Output } from '@angular/core'
import { DialogService } from 'src/app/shared/services/dialog.service'
import { EditInvoiceEmailComponent } from '../edit-invoice-email/edit-invoice-email.component'

@Component({
  selector: 'app-business-invoice',
  templateUrl: './business-invoice.component.html',
  styleUrls: ['./business-invoice.component.scss'],
  standalone: false
})
export class BusinessInvoiceComponent {
  @Input() public title: string
  @Input() public subtitle: string
  @Input() public subscribeButton: string
  @Input() public hasInvoiceViaEmail = false
  @Input() public invoiceViaEmail: string
  @Input() public primaryEmail: string
  @Input() public customerNumber: string
  @Output() public updateInvoiceEmail = new EventEmitter<string>()

  constructor(private dialogService: DialogService) { }

  public onEditInvoiceEmailForm(edit: boolean) {
    const dialogRef = this.dialogService.openProfileComponent({
      data: {
        title: this.title,
        customerNumber: this.customerNumber,
        invoiceViaEmail: this.invoiceViaEmail,
        primaryEmail: this.primaryEmail,
        edit
      },
      component: EditInvoiceEmailComponent
    })

    dialogRef.afterClosed().subscribe(email => {
      if (email !== null) {
        this.updateInvoiceEmail.emit(email)
      }
    })
  }
}
