import { Component } from '@angular/core'
import { AuthService } from '../../auth/auth.service'

@Component({
  selector: 'app-site-expired-session',
  templateUrl: './site-expired-session.component.html',
  styleUrls: ['./site-expired-session.component.scss'],
  standalone: false
})
export class SiteExpiredSessionComponent {

  constructor(private authService: AuthService) { }

  public loginPage() {
    this.authService.logoffAndRevokeTokens()
  }
}
