import { AbstractControl, ValidatorFn } from '@angular/forms'

import { isValid } from 'danish-ssn'

export const cprValidator: () => ValidatorFn = () =>
  (control: AbstractControl): { [key: string]: any } | null => {
    const cpr = control.value
    let cprValidation
    try {
      if (isValid(cpr)) {
        cprValidation = validateCprDate(cpr) ? null : { invalidCpr: cpr }
      } else {
        cprValidation = { invalidCpr: cpr }
      }
    } catch {
      cprValidation = { invalidCpr: cpr }
    }
    return cprValidation
  }


const validateCprDate = (cpr: string): boolean => {
  const digit7 = parseInt(cpr[6], 10)

  const dateSegments = cpr.substring(0, 6).match(/.{1,2}/g)
  const cprMonth = parseInt(dateSegments[1], 10) - 1
  const cprDay = parseInt(dateSegments[0], 10)
  let date = new Date(
    Date.UTC(
      parseInt(dateSegments[2], 10),
      parseInt(dateSegments[1], 10) - 1,
      parseInt(dateSegments[0], 10),
      0,
      0,
      0,
      0
    )
  )

  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()

  // century correction
  if (digit7 === 4 || digit7 === 9) {
    // 1900 or 2000
    if (year <= 1936) {
      date = new Date(Date.UTC(year + 100, month, day, 0, 0, 0, 0))
    }
  } else if (digit7 > 4) {
    // 1800 or 2000
    date = new Date(Date.UTC(year > 1957 ? year - 100 : year + 100, month, day, 0, 0, 0, 0))
  }
  const cprYear = date.getFullYear()
  if (!isValidDate(cprDay, cprMonth, cprYear)) {
    return false
  }

  return true
}

const daysInMonth = (month: number, year: number): number => {
  // m is 0 indexed: 0-11
  switch (month) {
  case 1:
    return (year % 4 === 0 && year % 100) || year % 400 === 0 ? 29 : 28
  case 8:
  case 3:
  case 5:
  case 10:
    return 30
  default:
    return 31
  }
}

const isValidDate = (day: number, month: number, year: number): boolean => {
  const currentYear: number = new Date().getFullYear()
  const userAge = currentYear - year
  return month >= 0 && month < 12 && day > 0 && day <= daysInMonth(month, year) && userAge >= 0 && userAge <= 120
}
