<!-- Loader -->
<app-loader *ngIf="loading$ | async"></app-loader>

<ng-container *ngIf="(installations$ | async) && !isInToolbar">
  <h2 class="title header-large" translate="userProfile.title"></h2>

  <!-- Customer relations -->
  <app-customer-relations
    class="flex flex-col mb-30px"
    [installations]="installations$ | async"
    [isInToolbar]="isInToolbar"
    (selectInstallation)="onSelectInstallation($event)"
    (addCustomerRelation)="onAddCustomerRelation()"
  >
  </app-customer-relations>

  <!-- Associated users -->
  <app-private-associated-users
    class="space"
    [customerNumber]="customerNumber$ | async"
    [associatedUsers]="associatedUsers$ | async"
  >
  </app-private-associated-users>

  <!-- Payment details -->
  <app-private-payment-details
    class="space"
    [paymentDetails]="paymentDetails$ | async"
    [supplierName]="supplierName$ | async"
    (subscribe)="onSubscribeTo($event)"
    (unsubscribe)="onUnsubscribeFrom($event)"
  >
  </app-private-payment-details>

  <!-- Installations -->
  <app-installations [installationDetails]="installationDetails$ | async">
  </app-installations>
</ng-container>

<ng-container *ngIf="(installations$ | async) && isInToolbar">
  <app-customer-relations
    class="flex flex-col mb-30px"
    [installations]="installations$ | async"
    [isInToolbar]="isInToolbar"
    (selectInstallation)="onSelectInstallation($event)"
    (addCustomerRelation)="onAddCustomerRelation()"
  >
  </app-customer-relations>
</ng-container>

