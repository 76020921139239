<mat-card class="mat-card mat-elevation-z1">
  <!-- Component for the selected installation -->
  <div
    *ngIf="isInToolbar"
    class="selected-installation flex flex-row items-center p-10px border border-gray-300 rounded cursor-pointer bg-white"
    (click)="toggleExpand()"
  >
    <div class="flex flex-col m-2">
      <app-selected-installation-cell
        [installation]="selectedItem.selectedInstallation"
        [customerNumber]="selectedItem.customerNumber"
      ></app-selected-installation-cell>
    </div>
    <div class="arrow-icon" [class.expanded]="isExpanded">
      <mat-icon svgIcon="evida_menu_arrow"></mat-icon>
    </div>
  </div>

  <div
    *ngIf="isExpanded"
    class="flex flex-col bg-white p-2 dropdown-scroll"
    [ngSwitch]="installationsArray?.length"
  >
    <ng-container *ngSwitchCase="0">
      <ng-container *ngTemplateOutlet="none"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="1">
      <!-- Single item can still have multiple installations -->
      <ng-container
        [ngSwitch]="installationsArray?.value[0]?.installations?.length"
      >
        <ng-container *ngSwitchCase="0">
          <ng-container *ngTemplateOutlet="none"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="1">
          <ng-container *ngTemplateOutlet="single"></ng-container>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <ng-container *ngTemplateOutlet="multiple"></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="multiple"></ng-container>
    </ng-container>
  </div>

  <app-add-customer-relation
    *ngIf="showAddCustomerRelationButton && !isInToolbar"
    [isBusiness]="isBusiness"
    (addCustomerRelation)="onAddCustomerRelation()"
  >
  </app-add-customer-relation>
</mat-card>

<ng-template #none>
  <div
    class="mb-30px"
    translate="userProfile.customerRelations.noRelationsFound"
  ></div>
</ng-template>

<ng-template #single>
  <form [formGroup]="formGroup">
    <div
      class="flex flex-col mb-30px"
      formArrayName="installations"
      *ngFor="let installation of installationsArray.value"
    >
      <div class="flex flex-row">
        <span
          class="body-header"
          translate="userProfile.customerRelations.customerNumber"
        ></span>
        <div class="body-copy ml-5px">
          {{ installation?.customerNumber }}
        </div>
        <span
          class="pl-1"
          *ngIf="
            installation.installations.length === 1 &&
            installation.installations[0].address
          "
        >
          -
          {{ formatAddress(installation.installations[0].address) }}</span
        >
      </div>
      <span
        class="body-header mt-20px"
        translate="userProfile.customerRelations.installationAddress"
      ></span>
      <span
        class="flex flex-col"
        *ngFor="let installation of installation.installations"
      >
        <app-installation-cell
          [installation]="installation"
          [showMeterInfo]="false"
        ></app-installation-cell>
      </span>
    </div>
  </form>
</ng-template>

<ng-template #multiple>
  <div class="flex flex-col">
    <form [formGroup]="formGroup">
      <mat-radio-group formArrayName="installations">
        <mat-radio-button
          class="flex body-copy"
          *ngFor="let installation of installationsArray.value"
          [value]="installation"
          (change)="onSelectInstallation(installation)"
          [checked]="selectedItem.id === installation.id"
        >
          <div class="flex flex-col sm:flex-row">
            <div class="flex flex-col w-full mb-15px mt-5px">
              <div class="flex flex-row">
                <span
                  class="body-header ml-2"
                  translate="userProfile.customerRelations.customerNumber"
                ></span>
                <span class="ml-5px">{{ installation.customerNumber }}</span>
                <span
                  class="pl-1"
                  *ngIf="
                    installation.installations.length === 1 &&
                    installation.installations[0].address
                  "
                >
                  -
                  {{
                    formatAddress(installation.installations[0].address)
                  }}</span
                >
              </div>
              <app-installation-selector
                *ngIf="selectedItem.id === installation.id"
                [installation]="installation"
                (installationSelected)="onSelectInstallation($event)"
              >
              </app-installation-selector>
            </div>
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </form>
  </div>
</ng-template>

