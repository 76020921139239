import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import { DialogComponent } from '../components/dialog/dialog.component'
import { Dialog } from '../models/dialog.model'

@Injectable()
export class DialogService {
  constructor(public dialog: MatDialog) { }

  public openComponent(data: Dialog): MatDialogRef<any> {
    const config: MatDialogConfig = {
      data: data.data,
      height: 'auto',
      maxWidth: '480px'
    }
    return this.dialog.open(data?.component, config)
  }

  public openProfileComponent(data: Dialog): MatDialogRef<any> {
    const config: MatDialogConfig = {
      data: data.data,
      height: 'auto',
      maxWidth: '480px',
      width: '480px'
    }
    return this.dialog.open(data?.component, config)
  }


  public open(data: Dialog) {
    const config: MatDialogConfig = {
      data,
      height: 'auto',
      maxWidth: '480px'
    }
    this.dialog.open(DialogComponent, config)
  }
}
