import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-info-message',
  templateUrl: './info-message.component.html',
  styleUrls: ['./info-message.component.scss'],
  standalone: false
})

export class InfoMessageComponent {
  @Input() public hint: string
}
