<mat-card appearance="outlined" class="mat-elevation-z1">
  <mat-card-title class="header-small" translate="userProfile.paymentDetails.title"></mat-card-title>
  <div class="flex flex-col mt-30px">
    <span class="body-header" translate="userProfile.installations.supplierName"></span>
    <div class="body-copy">{{ supplierName | emptyField }}</div>
    <div class="body-small mt-10px" [innerHTML]="'userProfile.paymentDetails.info' | translate"></div>
  </div>
  <hr class="mt-30px mb-30px">

  <!-- Payment service -->
  <app-payment-item
    [title]="'userProfile.paymentDetails.paymentService.title'"
    [tooltip]="'userProfile.paymentDetails.paymentService.tooltip'"
    [subscribeButton]="'userProfile.paymentDetails.paymentService.subscribeButton'"
    [paymentType]="paymentType.paymentservice"
    [image]="'../../../../../assets/image/betalingsservice.svg'"
    [isSignedUp]="paymentDetails?.paymentServiceActive"
    (subscribe)="onSubscribePaymentService(paymentDetails?.paymentServiceUrl)">
  </app-payment-item>

  <hr class="mt-30px mb-30px">

  <!-- Easy account -->
  <app-payment-item
    [title]="'userProfile.paymentDetails.easyAccount.title'"
    [subscribeButton]="'userProfile.paymentDetails.easyAccount.subscribeButton'"
    [unsubscribeButton]="'userProfile.paymentDetails.easyAccount.unsubscribeButton'"
    [paymentType]="paymentType.easyaccount"
    [consentLabel]="'userProfile.paymentDetails.easyAccount.consentLabel'"
    [birthDate]="paymentDetails?.birthDate"
    [image]="'../../../../../assets/image/nemkonto.svg'"
    [isSignedUp]="paymentDetails?.nemKontoActive"
    (consentAccepted)="onSubscribeEasyAccount($event)"
    (unsubscribe)="onUnsubscribeEasyAccount()">
  </app-payment-item>
</mat-card>

<div class="space"></div>

<mat-card appearance="outlined" class="mat-elevation-z1">
  <mat-card-title class="header-small" translate="userProfile.paymentDetails.digitalPost.title"></mat-card-title>
  <div class="flex flex-col mt-20px">
    <!-- Digital post -->
    <app-payment-item
      [title]="'userProfile.paymentDetails.digitalPost.header'"
      [subscribeButton]="'userProfile.paymentDetails.digitalPost.subscribeButton'"
      [unsubscribeButton]="'userProfile.paymentDetails.digitalPost.unsubscribeButton'"
      [paymentType]="paymentType.digitalpost"
      [consentLabel]="'userProfile.paymentDetails.digitalPost.consentLabel'"
      [birthDate]="paymentDetails?.birthDate"
      [isSignedUp]="paymentDetails?.digitalPostActive"
      (consentAccepted)="onSubscribeForDigitalPost($event)"
      (unsubscribe)="onUnsubscribeDigitalPost()">
    </app-payment-item>
  </div>
</mat-card>
