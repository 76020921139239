import { BreakpointObserver } from '@angular/cdk/layout'
import {
  AfterContentChecked,
  Component,
  HostListener,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core'
import { firstValueFrom, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { EvidaEvents } from 'src/app/tracking/evida-events'
import { TrackEvent } from 'src/app/tracking/google-analytics'
import { Breakpoints } from 'src/app/utils/breakpoints'
import { RedirectTo } from 'src/app/utils/redirect-to'
import { AuthService } from '../../auth/auth.service'
import { PrivateProfileService } from 'src/app/evida/profile/services/private-profile.service'
import { BusinessProfileService } from 'src/app/evida/profile/services/business-profile.service'

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  standalone: false
})
export class ToolbarComponent
implements AfterContentChecked, OnInit, OnDestroy
{
  public static readonly ELEMENT_ID = 'routerOutlet'

  public contentWidth$: Observable<number>
  public isLg$: Observable<boolean>
  public isMd$: Observable<boolean>

  public associatedUserName: string = ''
  public isProfileDropdownOpen = false
  public installations: object

  private globalClickListener: () => void

  @Input() public isLoginFlow = true
  @Output() dropdownStateChange = new EventEmitter<boolean>()

  @HostListener('window:resize')
  onResize() {
    const marginOffset = 35
    const logoOffset = 335
    const offsetWidth =
      document.getElementById(ToolbarComponent.ELEMENT_ID)?.offsetWidth +
      marginOffset
    this.contentWidth$ = this.isMd$.pipe(
      map((isMd) => {
        const mdOffset = offsetWidth - logoOffset
        const lgOfsett = !this.isLoginFlow ? mdOffset : offsetWidth
        return isMd ? mdOffset : lgOfsett
      }),
    )
  }

  constructor(
    private bpo: BreakpointObserver,
    public authService: AuthService,
    private privateProfileService: PrivateProfileService,
    private businessProfileService: BusinessProfileService,
  ) {
    this.isLg$ = this.bpo.observe(Breakpoints.lg).pipe(map((it) => it.matches))
    this.isMd$ = this.bpo
      .observe(Breakpoints.md_only)
      .pipe(map((it) => it.matches))
  }

  public ngAfterContentChecked() {
    this.onResize()
  }

  @TrackEvent(EvidaEvents.Toolbar.clicks.logo)
  public onLogoClick() {
    RedirectTo.loginOrWelcomeLandingPage(this.authService.isAuthenticated)
  }

  public toggleProfileDropdown() {
    this.isProfileDropdownOpen = !this.isProfileDropdownOpen
    this.dropdownStateChange.emit(this.isProfileDropdownOpen)
  }

  private async getPrivateInstallations() {
    return await firstValueFrom(this.privateProfileService.getInstallations())
  }

  private async getBusinessInstallations() {
    return await firstValueFrom(this.businessProfileService.getInstallations())
  }

  private async getInfo() {
    if (this.authService.isAuthenticated) {
      if (this.authService.isPrivateCustomer) {
        this.installations = await this.getPrivateInstallations()
      } else {
        this.installations = await this.getBusinessInstallations()
      }

      // Note: getting the user information from the first available installation (might need to change in the future)
      const customerNumber = this.installations[0].customerNumber
      const selectedInstallation =
        this.installations[0].installations[0].installationNumber

      const response = await firstValueFrom(
        this.privateProfileService.getAssociatedUsersInformation(
          customerNumber,
          selectedInstallation,
        ),
      )
      this.associatedUserName = response.associatedUsers.primary.name
    }
  }

  ngOnInit() {
    this.getInfo()
  }

  ngOnDestroy(): void {
    if (this.globalClickListener) {
      this.globalClickListener()
    }
  }
}
