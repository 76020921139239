import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-installation-info',
  templateUrl: './installation-info.component.html',
  standalone: false
})
export class InstallationInfoComponent {
    @Input() public text?: string
    @Input() public header: string
    @Input() public body?: string
    @Input() public htmlBody?: string
}
