import { Component, EventEmitter, Input, Output } from '@angular/core'
import { PaymentType } from '../../models/payment-type.model'

@Component({
  selector: 'app-payment-item',
  templateUrl: './payment-item.component.html',
  styleUrls: ['./payment-item.component.scss'],
  standalone: false
})
export class PaymentItemComponent {
  @Input() public title: string
  @Input() public subTitle: string
  @Input() public subscribeButton: string
  @Input() public unsubscribeButton: string
  @Input() public image: string
  @Input() public tooltip: string
  @Input() public isSignedUp = false
  @Input() public showButtons = true
  @Input() public paymentType: PaymentType = null
  @Input() public consentLabel?: string
  @Input() public birthDate?: string
  @Output() public subscribe = new EventEmitter()
  @Output() public unsubscribe = new EventEmitter()
  @Output() public consentAccepted = new EventEmitter<string | null>()

  public toggleConsentUi = false

  public get showConsent(): boolean {
    return !this.isSignedUp && (
      this.paymentType === PaymentType.easyaccount ||
      this.paymentType === PaymentType.digitalpost
    )
  }

  public get isEasyAccountOrDigitalPost(): boolean {
    return (
      this.paymentType === PaymentType.easyaccount ||
      this.paymentType === PaymentType.digitalpost)
  }

  public toggleUi() {
    this.toggleConsentUi = !this.toggleConsentUi
  }

  public onConsentAccepted(cpr: string | null) {
    this.toggleConsentUi = false
    this.consentAccepted.emit(cpr)
  }
}
