<div class="flex flex-col">
  <div class="body-header" translate="userProfile.paymentDetails.eanInvoice.title"></div>
  <div class="body-small mt-10px"
    translate="userProfile.paymentDetails.eanInvoice.subtitle"></div>

<div class="flex justify-between mt-30px" [ngSwitch]="eanStatus">
  <ng-container *ngSwitchCase="eanStatusValue.Unsubscribed">
    <ng-container *ngTemplateOutlet="unsubscribed"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="eanStatusValue.Subscribed">
    <ng-container *ngTemplateOutlet="subscribed"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="eanStatusValue.Awaiting">
    <ng-container *ngTemplateOutlet="awaiting"></ng-container>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="unsubscribed"></ng-container>
  </ng-container>
</div>
</div>

<ng-template #subscribed>
  <div class="subscribed flex">
    <mat-icon svgIcon="evida_registered" inline="true"></mat-icon>
    <span class="body-copy ml-10px"translate="userProfile.paymentDetails.eanInvoice.subscribed"></span>
  </div>
  <div class="edit-button" (click)="onEditEanInvoiceForm(true)">
    <mat-icon svgIcon="evida_edit" inline="true"></mat-icon>
  </div>
</ng-template>

<ng-template #unsubscribed>
  <app-evida-button class="button" type="primary" (clicked)="onEditEanInvoiceForm(false)">
    {{ 'userProfile.paymentDetails.eanInvoice.subscribeButton' | translate }}
  </app-evida-button>
</ng-template>

<ng-template #awaiting>
  <div class="info body-small" translate="userProfile.paymentDetails.eanInvoice.awaiting"></div>

</ng-template>

