import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core'
import { Address } from 'src/app/shared/services/backend'
import { AddressMapper } from 'src/app/soft-login/mappers/AddressMapper'
import { Utils } from 'src/app/utils/utils'
import { InstallationItem } from '../../../models/installation-item.model'

@Component({
  selector: 'app-installation-selector',
  templateUrl: './installation-selector.component.html',
  styleUrls: ['./installation-selector.component.scss'],
  standalone: false
})
export class InstallationSelectorComponent implements AfterViewInit, OnInit {
  public static readonly MAX_SCROLL_ITEMS = 3
  public isAccordionExpanded = false
  childRadioName = 'childInstallationGroup'

  @Input() public title: string
  @Input() public installation: InstallationItem
  @Output() public installationSelected = new EventEmitter<any>()

  constructor(private cdRef: ChangeDetectorRef) {}

  public get showScrollbar(): boolean {
    return (
      this.installation.installations.length >
      InstallationSelectorComponent.MAX_SCROLL_ITEMS
    )
  }

  public get showAddress(): boolean {
    return this.installation.installations.length > 1
  }

  public ngAfterViewInit() {
    Utils.scrollToTop()
  }

  public formatAddress(address: Address): string {
    return AddressMapper.formatAddress(address)
  }

  public selectInstallation(selectedInstallationNumber: string) {
    const selected = this.installation.installations.find(
      (inst) => inst.installationNumber === selectedInstallationNumber,
    )

    if (selected) {
      this.installation = {
        ...this.installation,
        selectedInstallation: selected,
      }

      this.installationSelected.emit(this.installation)
      this.cdRef.markForCheck()
    }
  }

  public onClickOutside() {
    this.isAccordionExpanded = false
  }

  ngOnInit() {
    setTimeout(
      (_) =>
        this.selectInstallation(
          this.installation.selectedInstallation.installationNumber,
        ),
      0,
    )
  }
}
