<app-loader *ngIf="loading$ | async"></app-loader>
<div class="flex flex-col">
  <h1 class="mb-30px header-medium" [translate]="data.title"></h1>
  <div class="flex flex-col">
    <form [formGroup]="formGroup">
      <div class="flex flex-col">
        <!-- EAN-number -->
        <app-form-input class="flex flex-col" title="userProfile.paymentDetails.eanInvoice.modal.eanNumber"
          mask="0000000000000" placeholder="userProfile.paymentDetails.eanInvoice.modal.eanNumberPlaceholder"
          [errorLookup]="data.edit ? {required: 'userProfile.paymentDetails.eanInvoice.modal.unsubscribeMessage' } : {}"
          [control]="formGroup.controls.eanNumber" [errorStateMatcher]="eanErrorMatcher">
        </app-form-input>
        <!-- Requisition number -->
        <app-form-input class="flex flex-col" title="userProfile.paymentDetails.eanInvoice.modal.requisitionNumber"
          placeholder="userProfile.paymentDetails.eanInvoice.modal.requisitionNumberPlaceholder"
          [control]="formGroup.controls.requisitionNumber">
        </app-form-input>
        <!-- Contact person -->
        <app-form-input class="flex flex-col" title="userProfile.paymentDetails.eanInvoice.modal.contactPerson"
          placeholder="userProfile.paymentDetails.eanInvoice.modal.contactPersonPlaceholder"
          [control]="formGroup.controls.contactPerson">
        </app-form-input>
      </div>
    </form>
  </div>

  <div class="flex justify-between mt-30px">
    <app-evida-button class="button" type="secondary" (clicked)="cancel()">
      {{ 'userProfile.paymentDetails.eanInvoice.modal.cancelButton' | translate }}
    </app-evida-button>
    <app-evida-button class="button" type="primary" (clicked)="submit()" [disabled]="!isValid">
      {{ submitButtonTitle | translate }}
    </app-evida-button>
  </div>
</div>
