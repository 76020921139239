<mat-card appearance="outlined" class="mat-elevation-z1">
    <mat-card-title class="header-small" translate="userProfile.paymentDetails.title"></mat-card-title>
    <div class="flex flex-col mt-30px">
        <div class="flex flex-col sm:flex-row" *ngIf="supplierName">
            <div class="flex flex-col">
                <span class="body-header" translate="userProfile.installations.businessSupplierName"></span>
                <div class="body-copy">{{ supplierName | emptyField }}</div>
            </div>
        </div>
      <div class="body-small mt-10px" [innerHTML]="'userProfile.paymentDetails.businessInfo' | translate"></div>
    </div>
    <hr class="mt-30px mb-30px">

    <!-- Only show payment service if EAN number is not found -->
    <ng-container *ngIf="!paymentDetails?.hasEanNumber">
        <!-- Payment service -->
        <app-payment-item
          [title]="'userProfile.paymentDetails.paymentService.businessTitle'"
          [tooltip]="'userProfile.paymentDetails.paymentService.businessTooltip'"
          [subTitle]="'userProfile.paymentDetails.paymentService.businessSubTitle'"
          [subscribeButton]="'userProfile.paymentDetails.paymentService.subscribeButton'"
          [paymentType]="paymentType.paymentservice"
          [image]="'../../../../assets/image/betalingsservice.svg'"
          [isSignedUp]="paymentDetails?.paymentServiceActive"
          (subscribe)="onSubscribePaymentService(paymentDetails?.paymentServiceUrl)">
        </app-payment-item>
        <hr class="mt-30px mb-30px">
    </ng-container>

    <!-- Easy account -->
    <app-payment-item *ngIf="paymentDetails?.isBusiness"
        [title]="'userProfile.paymentDetails.easyAccount.businessTitle'"
        [paymentType]="paymentType.easyaccount"
        [image]="'../../../../assets/image/nemkonto.svg'"
        [showButtons]="false" >
    </app-payment-item>

    <!-- Ean invoice-->
    <ng-container *ngIf="eanStatus">
      <hr class="mt-30px mb-30px">
      <app-ean-invoice
        [eanNumber]="paymentDetails?.eanNumber"
        [eanStatus]="eanStatus"
        [customerNumber]="paymentDetails?.customerNumber"
        (updateEanInvoice)="updateEanInvoice.emit()">
      </app-ean-invoice>
    </ng-container>

    <ng-container *ngIf="features.enableInvoice">
        <hr class="mt-30px mb-30px">

        <!-- Invoice account -->
        <app-business-invoice
            [title]="'userProfile.paymentDetails.businessInvoice.invoice.title'"
            [subtitle]="'userProfile.paymentDetails.businessInvoice.invoice.subtitle'"
            [subscribeButton]="'userProfile.paymentDetails.businessInvoice.invoice.subscribeButton'"
            [hasInvoiceViaEmail]="paymentDetails?.hasInvoiceViaEmail"
            [primaryEmail]="paymentDetails?.primaryEmail"
            [invoiceViaEmail]="paymentDetails?.invoiceViaEmail"
            [customerNumber]="paymentDetails?.customerNumber"
            (updateInvoiceEmail)="updateInvoiceEmail.emit($event)"
        ></app-business-invoice>
    </ng-container>
</mat-card>

<div class="space"></div>

