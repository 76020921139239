<div *ngIf="consentLabel" class="consent flex flex-col mt-30px">
  <form [formGroup]="onlyConsentFormGroup" *ngIf="birthDate" class="flex flex-col">
    <div class="flex flex-col">
      <div class="flex flex-col mb-20px">
        <span class="flex label body-header" translate="userProfile.associatedUsers.cpr">
          <app-tooltip [text]="'userProfile.associatedUsers.cprTooltip' | translate"></app-tooltip>
        </span>
        <span class="body-copy">{{ birthDate | date : 'ddMMyy' }}-xxxx</span>
      </div>
    </div>

    <div class="flex flex-col">
      <mat-checkbox [disableRipple]="true" formControlName="consent">
        <span [innerHtml]="consentLabel | translate"></span>
      </mat-checkbox>
      <app-evida-button class="button mt-30px" type="primary" (clicked)="onSubmit()"
        [disabled]="onlyConsentFormGroup.invalid">
        {{ 'shared.buttons.save' | translate}}
      </app-evida-button>
    </div>
  </form>

  <form [formGroup]="formGroup" *ngIf="!birthDate" class="flex flex-col">
    <div class="flex flex-col sm:w-full md:max-w-285px">
      <mat-card-content>
        <app-form-input title="userProfile.paymentDetails.consest.enterCprTitle" placeholder="xxxxxx-xxxx"
          [errorLookup]="{ incorrect: 'userProfile.paymentDetails.consest.invalid' | translate }" [control]="formGroup.controls?.cpr" mask="000000-0000"  (blurred)="handleCprErrorLabel()">
        </app-form-input>
      </mat-card-content>
    </div>

    <div class="flex flex-col">
      <mat-checkbox [disableRipple]="true" formControlName="consent">
        <span [innerHtml]="consentLabel | translate"></span>
      </mat-checkbox>
      <app-evida-button class="button mt-30px" type="primary" (clicked)="onSubmit()" [disabled]="formGroup.invalid">
        {{ 'shared.buttons.save' | translate}}
      </app-evida-button>
    </div>
  </form>
</div>
