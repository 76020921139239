import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-evida-bullet',
  templateUrl: './evida-bullet.component.html',
  styleUrls: ['./evida-bullet.component.scss'],
  standalone: false
})
export class EvidaBulletComponent {
  @Input() public content?: string
  @Input() public icon?: string
  @Input() public bulletClass = ''
  @Input() public centeredText = false

  constructor() {}
}
