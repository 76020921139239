import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject, catchError, EMPTY, map, Observable, of } from 'rxjs'
import { SessionStorageService } from 'src/app/login/services/session-storage.service'
import { SelectedInstallation } from 'src/app/shared/models/selected-installation.model'
import {
  PrivateProfileService as ApiPrivateProfileService,
  EditPrivateProfilePrimaryContactRequest,
  PrivateProfileCustomerInstallationDetailsResponse,
  ProfileListItem,
  UpdateCustomerCprRequest,
  UpdateDigitalPostConsentRequest,
  UpdateNemkontoStatusRequest
} from '../../../shared/services/backend'
import { RedirectTo } from '../../../utils/redirect-to'

@Injectable()
export class PrivateProfileService {
  private errors: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  public errors$: Observable<any> = this.errors.asObservable()

  constructor(
    private sessionStorageService: SessionStorageService,
    private profileService: ApiPrivateProfileService,
    private router: Router
  ) { }

  public setSelectedInstallation(installation: SelectedInstallation) {
    this.sessionStorageService.setSelectedInstallation(installation)
  }

  public getInstallations(): Observable<Array<ProfileListItem>> {
    return this.profileService.apiProfilePrivateGet().pipe(
      map((response) => response.items.map(item => ({
        customerNumber: item.customerNumber,
        installations: item.installations
      })) as ProfileListItem[]),
      catchError((error: any) => {
        this.handleError(error)
        return of([])
      })
    )
  }

  public getAssociatedUsersInformation(
    customerNumber: string,
    installationNumber: string
  ): Observable<PrivateProfileCustomerInstallationDetailsResponse> {
    return this.profileService.
      apiProfilePrivateCustomerCustomerNumberInstallationInstallationNumberGet(customerNumber, installationNumber)
      .pipe(
        catchError((error: any) => {
          this.handleError(error)
          return of(null)
        })
      )
  }

  public updatePrimaryContact(
    customerNumber: string,
    request: EditPrivateProfilePrimaryContactRequest
  ): Observable<any> {
    return this.profileService.
      apiProfilePrivateCustomerCustomerNumberPrimaryContactPut(customerNumber, request, 'response', true)
      .pipe(
        catchError((error: any) => {
          this.handleError(error)
          return of(EMPTY)
        })
      )
  }

  public subscribeToEasyAccount(customerNumber: string, subscribe: boolean): Observable<any> {
    const request: UpdateNemkontoStatusRequest = { nemkontoActive: subscribe }
    return this.profileService.
      apiProfilePrivateCustomerCustomerNumberNemkontoPut(customerNumber, request, 'response', true)
      .pipe(
        catchError((error: any) => {
          this.handleError(error)
          return of(EMPTY)
        })
      )
  }

  public subscribeToDigitalPost(customerNumber: string, subscribe: boolean): Observable<any> {
    const request: UpdateDigitalPostConsentRequest = { digitalPostActive: subscribe }
    return this.profileService.
      apiProfilePrivateCustomerCustomerNumberEboksPut(customerNumber, request, 'response', true)
      .pipe(
        catchError((error: any) => {
          this.handleError(error)
          return of(EMPTY)
        })
      )
  }

  public saveCprNumber(customerNumber: string, cpr: string): Observable<any> {
    const request: UpdateCustomerCprRequest = { cpr, consent: true }
    return this.profileService.
      apiProfilePrivateCustomerCustomerNumberCprPut(customerNumber, request, 'response', true)
      .pipe(
        catchError((error: any) => {
          this.handleError(error)
          return of(EMPTY)
        })
      )
  }

  private handleError(error) {
    RedirectTo.internalServerError(this.router, error)
  }
}
