import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-base-card',
  templateUrl: './base-card.component.html',
  styleUrls: ['./base-card.component.scss'],
  standalone: false
})

export class BaseCardComponent {
  @Input() public loading: boolean
  @Input() public title: string
  @Input() public subTitle: string
  @Input() public header: string
  @Input() public body: string
  @Input() public message: string
  @Input() public headerClass = 'font-bold'
  @Input() public hideBackground = false
}
