<div class="evida-address">
  <p *ngIf="customerName" class="evida-address__customer-name">
    {{ customerName }}
  </p>
  <p class="evida-address__street">
    {{ address.street }}
    {{ address.houseNumber }}
    {{ address.houseLetter }}
    {{ address.floorNumber }}
    {{ address.apartment }}
  </p>
  <p class="evida-address__city">
    {{ address.zipcode }}
    {{ address.city }}
  </p>
</div>