<!-- Loader -->
<app-loader *ngIf="loading$ | async"></app-loader>

<ng-container *ngIf="(installations$ | async) && !isInToolbar">
  <h2 class="title header-large" translate="userProfile.title"></h2>

  <!-- Customer relations -->
  <app-customer-relations
    class="flex flex-col mb-30px"
    [showAddCustomerRelationButton]="!authService.isBusinessCredentialsLogin"
    [installations]="installations$ | async"
    [isBusiness]="true"
    [isInToolbar]="isInToolbar"
    (selectInstallation)="onSelectInstallation($event)"
    (addCustomerRelation)="onAddCustomerRelation()"
  >
  </app-customer-relations>

  <!-- Associated users -->
  <app-business-associated-users
    class="space"
    [customerNumber]="customerNumber$ | async"
    [associatedUsers]="associatedUsers$ | async"
    (updateInvoiceEmail)="onUpdateInvoiceEmail($event)"
    (updateModelAndUi)="onUpdateModelAndUi()"
  >
  </app-business-associated-users>

  <!-- Payment details -->
  <app-business-payment-details
    class="space"
    [paymentDetails]="paymentDetails$ | async"
    [supplierName]="supplierName$ | async"
    [eanStatus]="eanStatus$ | async"
    (subscribe)="onSubscribePaymentService($event)"
    (updateInvoiceEmail)="onUpdateInvoiceEmail($event)"
    (updateEanInvoice)="onUpdateEanInvoice()"
  >
  </app-business-payment-details>

  <!-- Installations -->
  <app-installations [installationDetails]="installationDetails$ | async">
  </app-installations>
</ng-container>
<ng-container *ngIf="(installations$ | async) && isInToolbar">
  <app-customer-relations
    class="flex flex-col mb-30px"
    [showAddCustomerRelationButton]="!authService.isBusinessCredentialsLogin"
    [installations]="installations$ | async"
    [isBusiness]="true"
    [isInToolbar]="isInToolbar"
    (selectInstallation)="onSelectInstallation($event)"
    (addCustomerRelation)="onAddCustomerRelation()"
  >
  </app-customer-relations>
</ng-container>
