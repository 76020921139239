<div class="flex flex-col" title="">
    <span class="body-header" [ngClass]="{ 'mb-10px': !subTitle }" [translate]="title"></span>
    <span class="body-copy mb-10px" *ngIf="subTitle" [translate]="subTitle"></span>

    <!-- Primary user information -->
    <div class="flex flex-row" *ngIf="associatedUser">
        <div class="flex flex-col flex-1">
            <div class="flex justify-between flex-col md:flex-row">
                <div class="flex flex-col mb-15px md:mb-0 flex-1">
                    <span class="label body-header" translate="userProfile.associatedUsers.name" title=""></span>
                    <span class="body-copy" title="">{{ associatedUser?.name | emptyField }}</span>
                </div>

                <div class="flex flex-col mb-15px md:mb-0 flex-1">
                    <span class="label body-header" translate="userProfile.associatedUsers.email" title=""></span>
                    <span class="body-copy" title="">{{ associatedUser?.email | emptyField }}</span>
                </div>

                <div class="flex flex-col mb-15px md:mb-0 flex-1">
                    <span class="label body-header" translate="userProfile.associatedUsers.mobilnummer" title=""></span>
                    <span class="body-copy" title="">{{ formattedCellPhoneNumber | emptyField }}</span>
                </div>

                <div class="flex flex-col mb-15px md:mb-0 flex-1" *ngIf="associatedUser.isBusiness">
                    <span class="label body-header" translate="userProfile.associatedUsers.phonenummer" title=""></span>
                    <span class="body-copy" title="">{{ formattedPhoneNumber | emptyField }}</span>
                </div>

                <!-- Show CPR for private users -->
                <div class="flex-col flex md:mb-0" *ngIf="!associatedUser.isBusiness">
                    <div class="flex justify-start md:justify-end">
                        <span class="label body-header text-left md:text-right"
                            translate="userProfile.associatedUsers.cpr" title=""></span>
                        <app-tooltip [text]="'userProfile.associatedUsers.cprTooltip' | translate"></app-tooltip>
                    </div>
                    <span class="contents body-copy w-180px text-right mr-30px md:text-left md:block">
                        <span *ngIf="associatedUser?.birthDate" class="text-left md:text-right" title="">{{
                            associatedUser?.birthDate | date : 'ddMMyy'
                            }}-xxxx</span>
                        <span class="text-left" title="" *ngIf="!associatedUser?.birthDate">-</span>
                    </span>
                </div>

                <!-- Show CVR for business users -->
                <div class="flex-col flex md:mb-0" *ngIf="associatedUser.isBusiness">
                    <div class="flex justify-start md:justify-end">
                        <span class="label body-header text-left md:text-right"
                            translate="userProfile.associatedUsers.cvr" title=""></span>
                        <app-tooltip [text]="'userProfile.associatedUsers.cvrTooltip' | translate"></app-tooltip>
                    </div>
                    <span class="contents body-copy w-180px text-right mr-30px md:text-left md:block">
                        <span *ngIf="associatedUser?.cvr" class="text-left md:text-right" title="">{{
                            associatedUser?.cvr }}</span>
                        <span class="text-left" title="" *ngIf="!associatedUser?.cvr">-</span>
                    </span>
                </div>
            </div>
        </div>

        <div class="flex flex-col cursor-pointer justify-center ml-30px md:justify-start">
            <mat-icon svgIcon="evida_edit" inline="true" (click)="editUserForm.emit(associatedUser)"></mat-icon>
        </div>
    </div>

    <!-- Secondary user name -->
    <div class="flex flex-row" *ngIf="secondaryName">
        <div class="flex flex-col flex-1">
            <div class="flex flex-col mb-15px md:mb-0 flex-1">
                <span class="label body-header" translate="userProfile.associatedUsers.name" title=""></span>
                <span class="body-copy" title="">{{ secondaryName }}</span>
            </div>
        </div>
    </div>
</div>

<div *ngIf="divider" class="divider"></div>