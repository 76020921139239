import { Component, EventEmitter, Input, Output } from '@angular/core'
import { AssociatedUser } from '../../models/associated-user.model'

@Component({
  selector: 'app-associated-user',
  templateUrl: './associated-user.component.html',
  styleUrls: ['./associated-user.component.scss'],
  standalone: false
})
export class AssociatedUserComponent {
  @Input() public title: string
  @Input() public subTitle?: string
  @Input() public associatedUser: AssociatedUser
  @Input() public secondaryName: string | null
  @Input() public divider = false
  @Output() public showInfo = new EventEmitter()
  @Output() public editUserForm = new EventEmitter<any>()

  public get formattedCellPhoneNumber(): string | null {
    return this.formatString(this.associatedUser.cellPhonePrefix, this.associatedUser.formattedCellPhoneNumber)
  }

  public get formattedPhoneNumber(): string | null {
    return this.formatString(this.associatedUser.phonePrefix, this.associatedUser.formattedPhoneNumber)
  }

  public formatString(countryCode: string, phoneNumber: string): string | null {
    if (countryCode && phoneNumber) {
      const countryCodeWithPlus = countryCode.substring(0, 1) === '+' ? countryCode : `+${countryCode}`
      return `${countryCodeWithPlus} ${phoneNumber}`
    }
    return null
  }
}
