<div
  class="installations flex flex-col w-full ml-24px mt-5px"
  (appClickOutside)="onClickOutside()"
>
  <span
    class="mb-10px"
    *ngIf="installation?.installations.length > 1"
    [translate]="title"
  ></span>

  <div class="flex flex-col" [ngSwitch]="installation?.installations.length">
    <ng-container *ngSwitchCase="1">
      <span
        class="flex flex-col body-copy whitespace-pre-line"
        *ngFor="let installation of installation?.installations"
      >
        <app-installation-cell
          [installation]="installation"
          [showAddress]="showAddress"
          [showMeterInfo]="true"
        ></app-installation-cell>
      </span>
    </ng-container>

    <ng-container class="flex flex-col ml-30px" *ngSwitchDefault>
      <mat-radio-group
        [name]="childRadioName"
        [value]="installation.selectedInstallation.installationNumber"
        (change)="selectInstallation($event.value)"
      >
        <mat-radio-button
          [name]="childRadioName"
          *ngFor="let i of installation.installations"
          [value]="i.installationNumber"
        >
          <div class="mb-15px mt-5px ml-15px">
            <app-installation-cell
              [installation]="i"
              [showAddress]="showAddress"
              [showMeterInfo]="true"
            ></app-installation-cell>
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </ng-container>
  </div>
</div>
