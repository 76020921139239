import { AfterContentChecked, ChangeDetectorRef, Component, Inject } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Observable, of } from 'rxjs'
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component'
import { Patterns } from 'src/app/shared/models/patterns.model'
import { LoadingStateService } from 'src/app/shared/services/loading-state.service'

@Component({
  selector: 'app-edit-invoice-email',
  templateUrl: './edit-invoice-email.component.html',
  styleUrls: ['./edit-invoice-email.component.scss'],
  standalone: false
})
export class EditInvoiceEmailComponent implements AfterContentChecked {
  public loading$: Observable<boolean> = of(false)
  /* eslint-disable max-len */
  public formGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [
      Validators.pattern(this.patterns.emailPattern),
      this.data.edit ? Validators.nullValidator : Validators.required
    ])
  })

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private loadingStateService: LoadingStateService,
    private patterns: Patterns,
    private dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.formGroup.controls.email.setValue(data.invoiceViaEmail)
    }
    this.loadingStateService.dialogRef = this.dialogRef

    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.cancel()
      }
    })
    this.dialogRef.backdropClick().subscribe(_ => {
      this.cancel()
    })
  }

  public ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges()
  }

  public cancel() {
    this.loadingStateService.dialogRef = null
    this.dialogRef.close(null)
  }

  public submit() {
    if (this.formGroup.valid) {
      this.dialogRef.close(this.formGroup.value.email)
    }
  }

  public get submitButtonTitle(): string {
    return `userProfile.paymentDetails.businessInvoice.${this.data.edit ? 'updateButton' : 'submitButton'}`
  }
}
