import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core'
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms'
import { Subject, takeUntil } from 'rxjs'
import { CountryCode, countryCodes } from '../../models/country-codes.model'
import { Masks } from '../../models/masks'
import { Patterns } from '../../models/patterns.model'


export interface PhoneNumber {
  countryCode: string;
  phoneNumber: string;
}

@Component({
  selector: 'app-evida-phone-number',
  templateUrl: 'evida-phone-number.component.html',
  styleUrls: ['evida-phone-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EvidaPhoneNumberComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: EvidaPhoneNumberComponent,
    },
  ],
  standalone: false
})
export class EvidaPhoneNumberComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() required = false
  @Input() disabled = false
  @Input() label = 'shared.cellphone'
  @Input() phoneNumberPlaceholder = 'shared.masks.phonenumber.placeholder'
  @Input() invalidPhoneNumberText = 'shared.errormessages.cellPhoneNumber'
  @Input() value: PhoneNumber = {
    countryCode: '45',
    phoneNumber: '',
  }
  @Input() set resetPhoneNumber(reset: boolean) {
    if (reset) {
      this.setupForm()
    }
  }

  public countryCodes: CountryCode[] = countryCodes
  public maskForCountryCode = '0000000000000000'

  public translations: any
  public phoneNumberFormGroup: UntypedFormGroup = new UntypedFormGroup({
    countryCode: new UntypedFormControl(this.value.countryCode),
    phoneNumber: new UntypedFormControl(this.value.phoneNumber),
  })

  private viewHasBeenDestroyed = new Subject()

  constructor(private patterns: Patterns, private masks: Masks) {}

  public ngOnInit() {
    this.setupForm()
  }

  public ngOnDestroy() {
    this.viewHasBeenDestroyed.next({})
  }

  private setupForm() {
    this.phoneNumberFormGroup = new UntypedFormGroup({
      countryCode: new UntypedFormControl(this.value.countryCode),
      phoneNumber: new UntypedFormControl(this.value.phoneNumber),
    })
    this.setupPhoneNumberValidation(this.value.countryCode)
    this
      .phoneNumberFormGroup
      .valueChanges
      .pipe(takeUntil(this.viewHasBeenDestroyed))
      .subscribe(() => this.updateValue())

    queueMicrotask(() => this.phoneNumberFormGroup.updateValueAndValidity())
  }

  public onTouched: any = () => {}
  public onChange: any = (_value: PhoneNumber) => {}

  public writeValue(value: PhoneNumber) {
    this.value = value ?? { countryCode: '45', phoneNumber: '', }
  }
  public registerOnChange(fn: any) {
    this.onChange = fn
  }
  public registerOnTouched(fn: any) {
    this.onTouched = fn
  }
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  public setupPhoneNumberValidation(countryCode: string): void {
    this.maskForCountryCode = this.masks.getMaskForCountryCode(countryCode)
    this.phoneNumberFormGroup.controls.phoneNumber.setValidators([
      ...(this.required ? [Validators.required] : []),
      ...(this.patterns.phoneNumberValidatorForCountryCode(countryCode))
    ])
  }

  public countryCodeChanged(value: string) {
    this.phoneNumberFormGroup.controls.countryCode?.setValue(value)
    this.setupPhoneNumberValidation(value)
    this.updateValue()
  }

  public phoneNumberChanged(value: string) {
    this.phoneNumberFormGroup.controls.phoneNumber?.setValue(value)
    this.updateValue()
  }

  public updateValue() {
    this.value = {
      countryCode: this.phoneNumberFormGroup.controls.countryCode.value,
      phoneNumber: this.phoneNumberFormGroup.controls.phoneNumber.value,
    }
    this.onChange(this.value)
    this.onTouched()
  }

  public validate(): ValidationErrors | null {
    return this.isPhoneNumberFormGroupInvalid ? { notValid: true } : null
  }

  private get isPhoneNumberFormGroupInvalid(): boolean {
    const phoneNumberEmpty = this.phoneNumberFormGroup.controls.phoneNumber.value.trim() === ''
    const isFormInvalid = (this.phoneNumberFormGroup.invalid || phoneNumberEmpty)
    const isDK = this.phoneNumberFormGroup.controls.countryCode.value === '45'

    // We only validate DK phone numbers, there is no validation for other country codes
    if (this.required) {
      return isDK ? isFormInvalid : phoneNumberEmpty
    } else {
      if (phoneNumberEmpty) {
        return false
      } else {
        return isDK ? isFormInvalid : false
      }
    }
  }
}
