import { Component, Input } from '@angular/core'
import { DialogService } from 'src/app/shared/services/dialog.service'
import { AssociatedUsers } from '../../../models/associated-users.model'
import { EditUserUtils } from '../../../utils/edit-user.utils'
import { EditPrivateUserComponent } from '../edit-user/edit-private-user.component'

@Component({
  selector: 'app-private-associated-users',
  templateUrl: './private-associated-users.component.html',
  standalone: false
})
export class PrivateAssociatedUsersComponent {
  @Input() public associatedUsers: AssociatedUsers
  @Input() public customerNumber: string

  constructor(private dialogService: DialogService) { }

  public onEditUserForm(user: AssociatedUsers) {
    const dialogRef = this.dialogService.openProfileComponent({
      data: { ...user, customerNumber: this.customerNumber },
      component: EditPrivateUserComponent
    })

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        /* eslint-disable max-len */
        this.associatedUsers.primary.email = EditUserUtils.formatInputValue(this.associatedUsers.primary.email, response.email)
        this.associatedUsers.primary.cellPhonePrefix = EditUserUtils.formatInputValue(this.associatedUsers.primary.cellPhonePrefix, response.cellPhoneCountryCode)
        this.associatedUsers.primary.formattedCellPhoneNumber = EditUserUtils.formatInputValue(this.associatedUsers.primary.formattedCellPhoneNumber, response.cellPhoneNumber)
      }
    })
  }
}
