import { Component } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'app-profile-nav-item',
  templateUrl: './profile-nav-item.component.html',
  styleUrls: ['./profile-nav-item.component.scss'],
  standalone: false
})
export class ProfileNavItemComponent {
  constructor(private router: Router) {}

  public onOpenProfilePage() {
    this.router.navigate(['/evida/profile'])
  }
}
