import { Pipe, PipeTransform } from '@angular/core'
import { isNil } from 'lodash-es'

@Pipe({
  name: 'emptyField',
  standalone: false
})
export class EmptyFieldPipe implements PipeTransform {
  transform(value: any): string {
    if (isNil(value) || value === '') {
      return '-'
    }
    return value
  }
}
