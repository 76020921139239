import { PhoneNumber } from 'src/app/shared/components/evida-phone-number/evida-phone-number.component'
import { AssociatedUsers } from '../models/associated-users.model'

export class EditUserUtils {
  public static formatPhoneNumber(countryCode: string, phoneNumber: string): PhoneNumber {
    return {
      countryCode: EditUserUtils.removePlus(countryCode) ?? '45',
      phoneNumber: EditUserUtils.removeEmptySpace(phoneNumber) ?? ''
    }
  }

  public static isValueModified(dataValue: string, formValue: string): boolean {
    // remove + if country code
    if (dataValue?.substring(0, 1) === '+') {
      dataValue = EditUserUtils.removePlus(dataValue)
      formValue = EditUserUtils.removePlus(formValue)
    }
    return EditUserUtils.removeEmptySpace(dataValue) !== EditUserUtils.removeEmptySpace(formValue)
  }

  public static formatInputValue(originaValue: string, value: string): string | null {
    if (value?.length === 0) {
      return null
    }
    if (value?.length > 0) {
      return value
    }
    return originaValue
  }

  public static getSyncroniseEmailDialog(associatedUser: AssociatedUsers, updatedEmail: string): 'updated' | 'deleted' | null {
    if (EditUserUtils.primaryEmailUpdated(associatedUser, updatedEmail)) {
      return 'updated'
    } else if (EditUserUtils.primaryEmailDeleted(associatedUser, updatedEmail)) {
      return 'deleted'
    } else {
      return null
    }
  }

  public static primaryEmailUpdated(associatedUser: AssociatedUsers, updatedEmail: string): boolean {
    const isPrimaryEmailUpdated = EditUserUtils.isPrimaryEmailUpdated(associatedUser.primary.email, updatedEmail)
    const isPrimaryEmailEmpty = EditUserUtils.isEmptyEmail(updatedEmail)
    const isSameEmail = EditUserUtils.isSameEmail(associatedUser)
    return isPrimaryEmailUpdated && isSameEmail && !isPrimaryEmailEmpty
  }

  public static primaryEmailDeleted(associatedUser: AssociatedUsers, updatedEmail: string): boolean {
    const isPrimaryEmailEmpty = EditUserUtils.isEmptyEmail(updatedEmail)
    const isSameEmail = EditUserUtils.isSameEmail(associatedUser)
    return isPrimaryEmailEmpty && isSameEmail
  }

  private static isPrimaryEmailUpdated(original: string, updated: string): boolean {
    return original !== updated
  }

  private static isSameEmail(associatedUser: AssociatedUsers): boolean {
    return associatedUser.primary?.email === associatedUser.primary?.invoiceViaEmail
  }

  private static isEmptyEmail(email: string): boolean {
    return email?.length === 0
  }

  private static removeEmptySpace(value: string): string {
    return value?.replace(/\s/g, '')
  }

  private static removePlus(value: string): string {
    return value?.replace('+', '')
  }
}
