import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-profile-dropdown',
  templateUrl: './profile-dropdown.component.html',
  styleUrls: ['./profile-dropdown.component.scss'],
  standalone: false,
})
export class ProfileDropdownComponent {
  @Input() isOpen = false
}
