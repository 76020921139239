import { Component, Input } from '@angular/core'
import { Address } from 'src/app/shared/services/backend/model/address'

@Component({
  selector: 'app-evida-address',
  templateUrl: 'evida-address.component.html',
  styleUrls: ['evida-address.component.scss'],
  standalone: false
})
export class EvidaAddressComponent {
  @Input() public address: Address
  @Input() public customerName?: string
}
