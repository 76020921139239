<!-- Profile page for private users -->
<ng-container *ngIf="authService.isPrivateCustomer">
  <app-private-profile [isInToolbar]="isInToolbar"></app-private-profile>
</ng-container>

<!-- Profile page for business users -->
<ng-container *ngIf="!authService.isPrivateCustomer">
  <app-business-profile [isInToolbar]="isInToolbar"></app-business-profile>
</ng-container>

