import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-readable-item',
  templateUrl: './readable-item.component.html',
  standalone: false
})
export class ReadableItemComponent  {
    @Input() public title: string
    @Input() public items?: Array<string>
    @Input() public topMargin = true
}
