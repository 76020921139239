<h2 class="header-medium px-medium mt-medium">{{ title }}</h2>
<p *ngIf="description && description.length > 0" class="px-medium mt-medium body-copy">{{ description }}</p>
<div *ngIf="messages?.pre" class="flex flex-col px-medium mt-medium">
  <div class="w-full body-copy">{{ messages?.pre }}</div>
</div>
<mat-card appearance="outlined" class="p-medium mt-medium">
  <ng-content></ng-content>
</mat-card>

<div *ngIf="messages?.post" class="flex flex-col px-medium mt-medium" [ngClass]="{ isXS: (isXS$ | async) }">
  <div class="w-full body-copy">
    {{ messages?.post }}
  </div>
</div>
