import { AfterContentChecked, ChangeDetectorRef, Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component'
import { LoadingStateService } from 'src/app/shared/services/loading-state.service'

@Component({
  selector: 'app-syncronise-email',
  templateUrl: './syncronise-email.component.html',
  styleUrls: ['./syncronise-email.component.scss'],
  standalone: false
})
export class SyncroniseEmailComponent implements AfterContentChecked {
  constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private loadingStateService: LoadingStateService,
        private dialogRef: MatDialogRef<DialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.loadingStateService.dialogRef = this.dialogRef
  }

  public ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges()
  }

  public cancel() {
    this.loadingStateService.dialogRef = null
    this.dialogRef.close(false)
  }

  public submit() {
    this.loadingStateService.dialogRef = null
    this.dialogRef.close(true)
  }
}
