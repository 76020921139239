import { Component, EventEmitter, Input, Output } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Dialog } from 'src/app/shared/models/dialog.model'
import { DialogService } from 'src/app/shared/services/dialog.service'
import { PaymentDetails } from '../../../models/payment-details.model'
import { PaymentType } from '../../../models/payment-type.model'
import { SubscribeTo } from '../../../models/subscribe-to.model'

@Component({
  selector: 'app-private-payment-details',
  templateUrl: './private-payment-details.component.html',
  styleUrls: ['./private-payment-details.component.scss'],
  standalone: false
})
export class PrivatePaymentDetailsComponent {
  @Input() public paymentDetails: PaymentDetails
  @Input() public supplierName: string
  @Output() public subscribe = new EventEmitter<SubscribeTo>()
  @Output() public unsubscribe = new EventEmitter<PaymentType>()

  public paymentType = PaymentType

  constructor(
    public translate: TranslateService,
    private dialogService: DialogService
  ) { }

  public onSubscribePaymentService(url: string) {
    this.subscribe.emit({ type: PaymentType.paymentservice, url })
  }

  public onSubscribeEasyAccount(cpr: string | null) {
    this.subscribe.emit({ type: PaymentType.easyaccount, cpr })
  }

  public onSubscribeForDigitalPost(cpr: string | null) {
    this.subscribe.emit({ type: PaymentType.digitalpost, cpr })
  }

  public onUnsubscribeEasyAccount() {
    const dialog: Dialog = {
      title: 'userProfile.paymentDetails.easyAccount.unsubscribeDialog.title',
      message: 'userProfile.paymentDetails.easyAccount.unsubscribeDialog.message',
      primaryButtonText: 'userProfile.paymentDetails.easyAccount.unsubscribeDialog.primaryButtonText',
      secondaryButtonText: 'userProfile.paymentDetails.easyAccount.unsubscribeDialog.secondaryButtonText',
      justifyBetween: true
    }
    this.showDialog(dialog, PaymentType.easyaccount)
  }

  public onUnsubscribeDigitalPost() {
    const dialog: Dialog = {
      title: 'userProfile.paymentDetails.digitalPost.unsubscribeDialog.title',
      message: 'userProfile.paymentDetails.digitalPost.unsubscribeDialog.message',
      primaryButtonText: 'userProfile.paymentDetails.digitalPost.unsubscribeDialog.primaryButtonText',
      secondaryButtonText: 'userProfile.paymentDetails.digitalPost.unsubscribeDialog.secondaryButtonText',
      justifyBetween: true
    }
    this.showDialog(dialog, PaymentType.digitalpost)
  }

  private showDialog(dialog: Dialog, type: PaymentType) {
    dialog.primaryButtonAction = () => {
      this.unsubscribe.emit(type)
    }
    this.dialogService.open(dialog)
  }
}
