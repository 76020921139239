import { DatePipe } from '@angular/common'
import { AfterContentChecked, ChangeDetectorRef, Component, Inject } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Observable, of } from 'rxjs'
import { LoadingStateService } from 'src/app/shared/services/loading-state.service'
import { DialogComponent } from '../../../../../shared/components/dialog/dialog.component'
import { PhoneNumber } from '../../../../../shared/components/evida-phone-number/evida-phone-number.component'
import { Patterns } from '../../../../../shared/models/patterns.model'
import { EditPrivateProfilePrimaryContactRequest } from '../../../../../shared/services/backend'
import { PrivateProfileService } from '../../../services/private-profile.service'
import { EditUserUtils } from '../../../utils/edit-user.utils'

@Component({
  selector: 'app-edit-private-user',
  templateUrl: './edit-private-user.component.html',
  styleUrls: ['./edit-private-user.component.scss'],
  standalone: false
})
export class EditPrivateUserComponent implements AfterContentChecked {
  public loading$: Observable<boolean> = of(false)
  /* eslint-disable max-len */
  public prefilledPhoneNumber: PhoneNumber = EditUserUtils.formatPhoneNumber(this.data?.cellPhonePrefix, this.data?.formattedCellPhoneNumber)
  public formGroup = new UntypedFormGroup({
    name: new UntypedFormControl({ value: '', disabled: true }),
    email: new UntypedFormControl('', [Validators.pattern(this.patterns.emailPattern)]),
    phone: new UntypedFormControl(this.prefilledPhoneNumber),
    cpr: new UntypedFormControl({ value: '', disabled: true }),
  })

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private profileService: PrivateProfileService,
    private loadingStateService: LoadingStateService,
    private datePipe: DatePipe,
    private patterns: Patterns,
    private dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.formGroup.controls.name.setValue(data.name)
      this.formGroup.controls.email.setValue(data.email)
      this.formGroup.controls.phone.setValue(this.prefilledPhoneNumber)
      this.formGroup.controls.cpr.setValue(this.formatedCprNumber)
    }
    this.loadingStateService.dialogRef = this.dialogRef
  }

  public ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges()
  }

  public cancel() {
    this.loadingStateService.dialogRef = this.dialogRef
    this.dialogRef.close(null)
  }

  public submit() {
    if (this.formGroup.valid) {
      this.loading$ = of(true)
      const request = this.contactRequest
      this.profileService.updatePrimaryContact(this.data.customerNumber, request)
        .subscribe(response => {
          if (response) {
            this.loading$ = of(false)
            this.dialogRef.close(request)
          }
        })
    }
  }

  private get formatedCprNumber(): string {
    const cprNumber = this.data.birthDate
    if (cprNumber) {
      return `${this.datePipe.transform(cprNumber, 'ddMMyy')}-xxxx`
    }
    return '-'
  }

  private get contactRequest(): EditPrivateProfilePrimaryContactRequest {
    const { email, phone } = this.formGroup.value

    const request: EditPrivateProfilePrimaryContactRequest = {
      name: this.data.name
    }
    if (EditUserUtils.isValueModified(this.data?.email, email)) {
      request.email = email
    }
    if (EditUserUtils.isValueModified(this.data?.cellPhonePrefix, phone.countryCode)) {
      request.cellPhoneCountryCode = phone.countryCode
    }
    if (EditUserUtils.isValueModified(this.data?.formattedCellPhoneNumber, phone.phoneNumber)) {
      request.cellPhoneNumber = phone.phoneNumber
    }
    return request
  }
}
