import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Inject,
} from '@angular/core'
import {
  AbstractControl,
  FormGroupDirective,
  NgForm,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog'
import { Observable, firstValueFrom, of } from 'rxjs'
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component'
import { Patterns } from 'src/app/shared/models/patterns.model'
import { UpdateBusinessProfileEanInvoiceRequest } from 'src/app/shared/services/backend'
import { LoadingStateService } from 'src/app/shared/services/loading-state.service'
import { BusinessProfileService } from '../../../services/business-profile.service'

export class EanErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: AbstractControl<any, any>, form: NgForm | FormGroupDirective): boolean {
    return control && control.invalid && ((form.value.eanNumber?.length === 0 && control.dirty) || control.touched)
  }
}

@Component({
  selector: 'app-edit-ean-invoice',
  templateUrl: './edit-ean-invoice.component.html',
  styleUrls: ['./edit-ean-invoice.component.scss'],
  standalone: false
})
export class EditEanInvoiceComponent implements AfterContentChecked {
  public loading$: Observable<boolean> = of(false)
  /* eslint-disable max-len */
  public formGroup = new UntypedFormGroup({
    eanNumber: new UntypedFormControl('', [
      Validators.pattern(this.patterns.eanNumberPattern),
      Validators.required
    ]),
    requisitionNumber: new UntypedFormControl(),
    contactPerson: new UntypedFormControl(),
  })
  public eanErrorMatcher: EanErrorStateMatcher

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private profileService: BusinessProfileService,
    private loadingStateService: LoadingStateService,
    private patterns: Patterns,
    private dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.formGroup.controls.eanNumber.setValue(data.eanNumber)
      this.formGroup.controls.requisitionNumber.setValue(
        data.requisitionNumber
      )
      this.formGroup.controls.contactPerson.setValue(data.contactPerson)
      this.eanErrorMatcher = this.data.edit ? new EanErrorStateMatcher() : null
    }
    this.loadingStateService.dialogRef = this.dialogRef

    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.cancel()
      }
    })
    this.dialogRef.backdropClick().subscribe((_) => {
      this.cancel()
    })
  }

  public ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges()
  }

  public cancel() {
    this.loadingStateService.dialogRef = null
    this.dialogRef.close(null)
  }

  public async submit() {
    if (this.isValid) {
      this.loading$ = of(true)
      if (this.eanInvoiceRequest.eanNumber) {
        const response = await firstValueFrom(this.profileService.updateEanInvoice(this.data.customerNumber, this.eanInvoiceRequest))
        if (response) {
          this.loading$ = of(false)
          this.dialogRef.close(response)
        }
      } else {
        const response = await firstValueFrom(this.profileService.deleteEanInvoice(this.data.customerNumber))
        if (response) {
          this.loading$ = of(false)
          this.dialogRef.close(response)
        }
      }
    }
  }

  public get isValid() {
    return this.data.edit ? !this.formGroup.controls.eanNumber.errors?.['pattern'] : this.formGroup.valid
  }

  public get submitButtonTitle(): string {
    return `userProfile.paymentDetails.eanInvoice.modal.${!this.data.edit
      ? 'subscribeButton'
      : this.formGroup.controls.eanNumber.errors?.['required']
        ? 'unsubscribeButton'
        : 'updateButton'
    }`
  }

  private get eanInvoiceRequest(): UpdateBusinessProfileEanInvoiceRequest {
    const { eanNumber, requisitionNumber, contactPerson } = this.formGroup.value
    const request: UpdateBusinessProfileEanInvoiceRequest = {
      eanNumber: eanNumber,
      requisitionNumber: requisitionNumber,
      contactPerson: contactPerson
    }
    return request
  }
}
