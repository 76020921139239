<div class="flex flex-col">
    <span class="title flex body-copy mb-20px">{{ data.title | translate }}</span>
    <span class="sub-title flex body-copy">{{ data.subtitle | translate }}</span>

    <div class="flex flex-col">
        <hr class="mt-30px mb-20px" />
        <span class="body-copy mb-20px" translate="userProfile.paymentDetails.syncroniseEmail.emailTitle"></span>
        <span class="label body-header" translate="userProfile.paymentDetails.syncroniseEmail.emailLabel"></span>
        <span class="body-copy" title="">{{ data.invoiceEmail }}</span>
        <hr class="mt-20px mb-30px" />
    </div>

    <div class="flex justify-between">
        <app-evida-button class="button" type="secondary" (clicked)="cancel()">
            {{ 'userProfile.paymentDetails.syncroniseEmail.secondaryButtonText' | translate }}
        </app-evida-button>
        <app-evida-button class="button" type="primary" (clicked)="submit()">
            {{ 'userProfile.paymentDetails.syncroniseEmail.primaryButtonText' | translate }}
        </app-evida-button>
    </div>
</div>