<!-- If contract is open - check for meter info -->
<ng-container *ngIf="!installation.contract?.isClosed">
  <div class="flex flex-row">
    <span
      class="body-header"
      translate="userProfile.customerRelations.customerNumber"
    ></span>
    <span class="ml-5px">{{ customerNumber }}</span>
  </div>

  <div class="flex flex-row w-full">
    <span
      class="body-header"
      translate="userProfile.customerRelations.installationTitle"
    ></span>
    <span class="ml-5px"> {{ formatAddress(installation.address) }}</span>

    <!-- Meter info found  -->
    <span
      *ngIf="meterInfo && showMeterInfo"
      class="flex flex-col ml-5px body-header"
      [ngClass]="{ 'small-font-size': smallSize }"
      translate="userProfile.customerRelations.meter"
    >
    </span>

    <span class="ml-5px">
      {{ this.installation.meterNumber }}
    </span>

    <!-- Meter info removed -->
    <strong
      *ngIf="!meterInfo"
      class="flex flex-col text-green ml-5px"
      [ngClass]="{ 'small-font-size': smallSize }"
      translate="customerRelations.removed"
    >
    </strong>
  </div>
</ng-container>

<!-- If contract is closed -->
<strong
  *ngIf="installation.contract?.isClosed"
  class="flex flex-col whitespace-pre-line text-green"
  [ngClass]="{ 'small-font-size': smallSize }"
  translate="customerRelations.closedContract"
>
</strong>
