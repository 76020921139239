<div class="flex flex-col">
  <img class="icon" [src]="image">
  <div class="flex">
    <div *ngIf="title" class="body-copy" [innerHTML]="title | translate"></div>
    <app-tooltip *ngIf="tooltip" [text]="tooltip | translate"></app-tooltip>
  </div>
  <div *ngIf="subTitle" class="body-small mt-10px" [innerHTML]="subTitle | translate"></div>

  <!-- Handle subscribe/unsubscribe buttons -->
  <div *ngIf="showButtons">
    <ng-container *ngTemplateOutlet="buttons"></ng-container>
  </div>

  <!-- Handle easy account and digital post -->
  <ng-container *ngIf="isEasyAccountOrDigitalPost">
    <!-- Show subscribe button that toggels consent/cpr UI -->
    <ng-container *ngIf="showButtons">
      <ng-container *ngIf="!isSignedUp && !toggleConsentUi">
        <ng-container class="flex justify-between">
          <app-evida-button class="butto mt-30px" type="primary" (clicked)="toggleUi()">{{ subscribeButton | translate }}
          </app-evida-button>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- Show consent and cpr UI -->
    <ng-container *ngIf="toggleConsentUi">
      <app-consent *ngIf="showConsent" [consentLabel]="consentLabel" [birthDate]="birthDate"
        (consentAccepted)="onConsentAccepted($event)">
      </app-consent>

      <div *ngIf="!showConsent" class="flex justify-between mt-30px">
        <div class="subscribed flex" *ngIf="isSignedUp">
          <mat-icon svgIcon="evida_registered" inline="true"></mat-icon>
          <span class="body-copy ml-10px" [translate]="'userProfile.paymentDetails.subscribed'"></span>
        </div>

        <app-evida-button *ngIf="subscribeButton && !isSignedUp" class="button" type="primary"
          (clicked)="subscribe.emit()">{{ subscribeButton | translate }}
        </app-evida-button>
        <app-evida-button *ngIf="unsubscribeButton && isSignedUp" class="button" type="secondary"
          (clicked)="unsubscribe.emit()">
          {{ unsubscribeButton | translate }}
        </app-evida-button>
      </div>
    </ng-container>
  </ng-container>
</div>

<!-- Subscribed/unsubscribed buttons -->
<ng-template #buttons>
  <div *ngIf="!showConsent" class="flex justify-between mt-30px">
    <div class="subscribed flex" *ngIf="isSignedUp">
        <mat-icon svgIcon="evida_registered" inline="true"></mat-icon>
        <span class="body-copy ml-10px" [translate]="'userProfile.paymentDetails.subscribed'"></span>
    </div>
    <app-evida-button *ngIf="subscribeButton && !isSignedUp" class="button" type="primary" (clicked)="subscribe.emit()">
      {{ subscribeButton | translate }}
    </app-evida-button>
    <app-evida-button *ngIf="unsubscribeButton && isSignedUp" class="button" type="secondary"
      (clicked)="unsubscribe.emit()">
      {{ unsubscribeButton | translate }}
    </app-evida-button>
  </div>
</ng-template>
