export enum MenuItemId {
  frontpage = 'frontpage',
  documents = 'documents',
  consumption = 'consumption',
  profile = 'profile',
  logout = 'logout',
  invoice = 'invoice', // submenu
  development = 'development', // submenu
  productionreadings = 'productionreadings', // submenu
  timedata = 'timedata', // submenu
  follow = 'follow' // submenu
}

export interface SubmenuItem {
  id: MenuItemId;
  title: string;
  routerLink: string;
  selected: boolean;
  hidden?: boolean
}

export class Menu {
  private static SUBMENU_ITEM_HEIGHT = 57
  public id: MenuItemId
  public title: string
  public selected: boolean
  public active?: boolean
  public icon?: string
  public routerLink?: string
  public submenu?: Array<SubmenuItem>

  constructor(
    id: MenuItemId,
    title: string,
    selected: boolean,
    active?: boolean,
    icon?: string,
    routerLink?: string,
    submenu?: Array<SubmenuItem>
  ) {
    this.id = id
    this.title = title
    this.selected = selected
    this.active = active
    this.icon = icon
    this.routerLink = routerLink
    this.submenu = submenu
  }

  public get selectedIcon(): string | undefined {
    return this.icon ? `${this.icon}-selected` : undefined
  }

  public deselectMenuItems(id: MenuItemId) {
    if (this.id !== id && this.selected) {
      this.selected = !this.selected
    }
  }

  public deselectSubmenuItems() {
    this.submenu?.forEach(item => item.selected = false)
    this.active = false
  }

  public get calculatedHeight(): string {
    const total = this.submenu.length * Menu.SUBMENU_ITEM_HEIGHT
    const hidden = this.submenu.filter(i => i.hidden === true).length * Menu.SUBMENU_ITEM_HEIGHT
    return String(total - hidden)
  }
}
