import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms'
import { SessionStorageService } from 'src/app/login/services/session-storage.service'
import { Address } from '../../../../shared/services/backend'
import { AddressMapper } from '../../../../soft-login/mappers/AddressMapper'
import { Utils } from '../../../../utils/utils'
import { InstallationItem } from '../../models/installation-item.model'

@Component({
  selector: 'app-customer-relations',
  templateUrl: './customer-relations.component.html',
  styleUrls: ['./customer-relations.component.scss'],
  standalone: false
})
export class CustomerRelationsComponent implements AfterViewInit {
  @Input() public showAddCustomerRelationButton = true
  @Input() public isBusiness = false
  @Input() public isInToolbar: boolean = false
  @Output() public selectInstallation = new EventEmitter<InstallationItem>()
  @Output() public addCustomerRelation = new EventEmitter()
  public selectedItem: InstallationItem = null
  public isExpanded: boolean = false

  public formGroup = new UntypedFormGroup({
    installations: new UntypedFormArray([]),
  })

  public get installationsArray() {
    return this.formGroup.controls.installations as UntypedFormArray
  }

  public toggleExpand() {
    this.isExpanded = !this.isExpanded
  }

  @Input() public set installations(installations: Array<InstallationItem>) {
    if (installations) {
      this.formGroup.controls.installations = new UntypedFormArray(
        // Add id to each installation
        installations.map((item, index) => {
          item?.installations.map((installation, id) => {
            installation.id = id
          })
          let selectedInstallation
          if (index === 0 || item.installations.length > 1) {
            selectedInstallation = item.installations[0]
          }
          this.selectedItem = selectedInstallation
          return new UntypedFormControl({
            ...item,
            selectedInstallation,
            id: index,
          })
        }),
      )
      // Select first installation or preselected installation from login page
      this.preselectInstallation()
    }
  }

  constructor(private sessionStorageService: SessionStorageService) {}

  public ngAfterViewInit() {
    Utils.scrollToTop()
    if (this.selectedItem) {
      this.selectInstallation.emit(this.selectedItem)
    }
  }

  public formatAddressAsTwoLinesHtml(address: Address): string {
    return AddressMapper.formatAddressAsTwoLinesHtml(address)
  }

  public formatAddress(address: Address): string {
    return AddressMapper.formatAddress(address)
  }

  public onSelectInstallation(selected: InstallationItem) {
    if (!selected || this.selectedItem === selected) {
      return
    }
    const single = selected.installations.length === 1 ? true : false
    const selectedInstallation = single
      ? selected.installations[0]
      : selected.selectedInstallation

    // Reset all selected installations if there is only one installation
    this.formGroup.controls.installations.value
      .filter((installation) => installation.installations.length === 1)
      .map((i) => (i.selectedInstallation = undefined))

    // Set selected installation
    this.formGroup.controls.installations.value
      .filter(
        (installation) =>
          installation.customerNumber === selected.customerNumber,
      )
      .map((i) => (i.selectedInstallation = selectedInstallation))

    this.selectedItem = { ...selected, selectedInstallation }

    this.selectInstallation.emit(selected)
  }

  public onAddCustomerRelation() {
    this.addCustomerRelation.emit()
  }

  private preselectInstallation() {
    this.selectedItem = this.formGroup.controls.installations.value[0]
    const selectedCustomerNumber = this.sessionStorageService.customerNumber
    const selectedInstallationNumber =
      this.sessionStorageService.installationNumber

    if (selectedCustomerNumber && selectedInstallationNumber) {
      const installationFound =
        this.formGroup.controls.installations.value.filter(
          (installations) =>
            installations.customerNumber === selectedCustomerNumber,
        )
      if (installationFound.length > 0) {
        installationFound.map((installation) => {
          this.selectedItem = installation

          installation?.installations.map((i) => {
            if (i.installationNumber === selectedInstallationNumber) {
              this.selectedItem.selectedInstallation = i
            }
          })
        })
      }
    }
    this.onSelectInstallation(this.selectedItem)
  }
}
