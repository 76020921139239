import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject, catchError, EMPTY, map, Observable, of } from 'rxjs'
import { SessionStorageService } from 'src/app/login/services/session-storage.service'
import { SelectedInstallation } from 'src/app/shared/models/selected-installation.model'
import { RedirectTo } from 'src/app/utils/redirect-to'
import {
  BusinessProfileService as ApiBusinessProfileService,
  BusinessProfileCustomerInstallationDetailsResponse,
  EditBusinessProfilePrimaryContactRequest,
  GetEanInvoiceStatusResponse,
  ProfileListItem,
  UpdateBusinessProfileEanInvoiceRequest,
  UpdateInvoiceViaEmailRequest
} from '../../../shared/services/backend'
import { ProductionEquipmentAccessService } from './production-equipment-access.service'

@Injectable()
export class BusinessProfileService {
  private errors: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  public errors$: Observable<any> = this.errors.asObservable()

  constructor(
    private productionEquipmentAccessService: ProductionEquipmentAccessService,
    private sessionStorageService: SessionStorageService,
    private profileService: ApiBusinessProfileService,
    private router: Router
  ) { }

  public async setSelectedInstallation(installation: SelectedInstallation) {
    this.sessionStorageService.setSelectedInstallation(installation)
    // Show/hide production equipment from main menu, only for business customers
    await this.productionEquipmentAccessService.getProductionEquipmentAccess()
  }

  public getInstallations(): Observable<Array<ProfileListItem>> {
    return this.profileService.apiProfileBusinessGet().pipe(
      map((response) => response.items.map(item => ({
        customerNumber: item.customerNumber,
        installations: item.installations
      })) as ProfileListItem[]),
      catchError((error: any) => {
        this.handleError(error)
        return of([])
      })
    )
  }

  public getAssociatedUsersInformation(
    customerNumber: string,
    installationNumber: string
  ): Observable<BusinessProfileCustomerInstallationDetailsResponse> {
    return this.profileService.
      apiProfileBusinessCustomerCustomerNumberInstallationInstallationNumberGet(customerNumber, installationNumber)
      .pipe(
        catchError((error: any) => {
          this.handleError(error)
          return of(null)
        })
      )
  }

  public updatePrimaryContact(
    customerNumber: string,
    request: EditBusinessProfilePrimaryContactRequest
  ): Observable<any> {
    return this.profileService.
      apiProfileBusinessCustomerCustomerNumberPrimaryContactPut(customerNumber, request, 'response', true)
      .pipe(
        catchError((error: any) => {
          this.handleError(error)
          return of(EMPTY)
        })
      )
  }

  public updateInvoiceEmail(
    customerNumber: string,
    request: UpdateInvoiceViaEmailRequest
  ): Observable<any> {
    return this.profileService.
      apiProfileBusinessCustomerCustomerNumberInvoicePut(customerNumber, request, 'response', true)
      .pipe(
        catchError((error: any) => {
          this.handleError(error)
          return of(EMPTY)
        })
      )
  }

  public getEanStatus(
    customerNumber: string
  ): Observable<GetEanInvoiceStatusResponse | null> {
    return this.profileService
      .apiProfileBusinessCustomerCustomerNumberEanInvoiceStatusGet(
        customerNumber
      )
      .pipe(
        catchError((_: any) => {
          return of(null)
        })
      )
  }

  public updateEanInvoice(
    customerNumber: string,
    request: UpdateBusinessProfileEanInvoiceRequest
  ): Observable<any> {
    return this.profileService
      .apiProfileBusinessCustomerCustomerNumberEanInvoicePut(
        customerNumber,
        request,
        'response',
        true
      )
      .pipe(
        catchError((error: any) => {
          this.handleError(error)
          return of(EMPTY)
        })
      )
  }

  public deleteEanInvoice(customerNumber: string): Observable<any>{
    return this.profileService.apiProfileBusinessCustomerCustomerNumberEanInvoiceDelete(
      customerNumber,
      'response',
      true
    )
      .pipe(
        catchError((error:any) =>{
          this.handleError(error)
          return of(EMPTY)
        })
      )
  }

  private handleError(error) {
    RedirectTo.internalServerError(this.router, error)
  }
}
