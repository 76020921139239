import { Component, EventEmitter, Input, Output } from '@angular/core'
import { EanStatus } from 'src/app/shared/services/backend'
import { DialogService } from 'src/app/shared/services/dialog.service'
import { EditEanInvoiceComponent } from '../edit-ean-invoice/edit-ean-invoice.component'

@Component({
  selector: 'app-ean-invoice',
  templateUrl: './ean-invoice.component.html',
  styleUrls: ['./ean-invoice.component.scss'],
  standalone: false
})
export class EanInvoiceComponent {
  @Input() public eanNumber: string | null
  @Input() public requisitionNumber: string | null
  @Input() public contactPerson: string | null
  @Input() public eanStatus: EanStatus
  @Input() public customerNumber: string
  @Output() public updateEanInvoice = new EventEmitter<void>()

  public eanStatusValue = EanStatus

  constructor(private dialogService: DialogService){}

  public onEditEanInvoiceForm(edit: boolean){
    const dialogRef = this.dialogService.openProfileComponent({
      data: {
        title: edit ? 'userProfile.paymentDetails.eanInvoice.modal.editTitle' : 'userProfile.paymentDetails.eanInvoice.modal.subscribeTitle',
        eanNumber: this.eanNumber,
        requisitionNumber: this.requisitionNumber,
        contactPerson: this.contactPerson,
        customerNumber: this.customerNumber,
        edit
      },
      component: EditEanInvoiceComponent
    })
    dialogRef.afterClosed().subscribe(eanInvoice =>{
      if(eanInvoice !== null){
        this.updateEanInvoice.emit()
      }
    })
  }
}
