import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { LoginRoutes } from 'src/app/login/models/login-routes.model'
import { Features } from '../models/features.model'
import { MyInstallationItem } from './backend/model/myInstallationItem'

@Injectable({
  providedIn: 'root',
})
export class ProfileLoginService {
  public selectedInstallation: MyInstallationItem = null
  public addNewRelation = false

  constructor(
    private router: Router,
    private features: Features) { }

  public addCustomerRelation() {
    this.addNewRelation = true
    this.router.navigate([`${LoginRoutes.login}/${LoginRoutes.flow.root}`])
  }

  public navigatToProfile(selected: MyInstallationItem) {
    this.addNewRelation = false
    this.selectedInstallation = selected ? selected : this.selectedInstallation
    this.router.navigate(['evida'])
  }

  public onCancelForgottenCredentials() {
    if (this.addNewRelation) {
      this.navigatToProfile(null)
    } else {
      window.history.back()
    }
  }
}
