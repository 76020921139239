import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output, ViewEncapsulation } from '@angular/core'
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { TranslatePipe } from '@ngx-translate/core'

@Component({
  selector: 'app-form-input',
  templateUrl: 'form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputComponent),
      multi: true
    },
    TranslatePipe
  ],
  standalone: false
})
export class FormInputComponent implements ControlValueAccessor, AfterViewChecked {
  @Input() title: string
  @Input() placeholder: string
  @Input() mask: string
  @Input() control: UntypedFormControl
  @Input() type: 'text' | 'password' | 'number' = 'text'
  @Input() public errorLookup: { [key: string]: string } = {}
  @Input() errorStateMatcher: ErrorStateMatcher
  @Output() blurred = new EventEmitter()

  _value = ''

  constructor(private cdRef: ChangeDetectorRef) { }

  public get required(): boolean {
    const hasValidator = this.control.validator?.length
    if (hasValidator) {
      const validator = this.control.validator({} as AbstractControl)
      return validator && validator.required
    }
    return false
  }

  public get errors(): Array<string> {
    const validationErrors = this.control?.errors || {}
    const keys = Object.keys(validationErrors)
    return keys.map((key) => this.errorLookup[key])
  }

  public get autocomplete(): string {
    return this.type === 'password' ? 'new-password' : 'off'
  }

  propagateChange: any = () => { }

  public ngAfterViewChecked() {
    this.cdRef.detectChanges()
  }

  public writeValue(value: any) {
    if (value) {
      this._value = value
    }
  }

  public registerOnChange(fn) {
    this.propagateChange = fn
  }

  public registerOnTouched() { }

  public onChange(event) {
    this.propagateChange(event.target.value)
  }
}
