import { Component, OnInit } from '@angular/core'
import { EvidaScreens } from 'src/app/tracking/evida-screens'
import { TrackEvent } from 'src/app/tracking/google-analytics'

@Component({
  selector: 'app-xellent-timeout',
  templateUrl: './xellent-timeout.component.html',
  styleUrls: ['./xellent-timeout.component.scss'],
  standalone: false
})
export class XellentTimeoutComponent implements OnInit {
  @TrackEvent(EvidaScreens.Error.timeout) // eslint-disable-line
  ngOnInit() {}  // eslint-disable-line

}
