<header class="flex flex-row justify-center w-full h-60px sm:h-80px">
  <nav>
    <!-- logo column -->
    <div
      class="w-full max-w-menu flex flex-row justify-start lg:justify-end items-center gap-x-30px"
      [ngClass]="{ '-ml-35px': isLg$ | async }"
    >
      <div class="flex-shrink-0 logo" (click)="onLogoClick()">
        <mat-icon svgIcon="evida_my_evida_logo" inline="true"></mat-icon>
      </div>
    </div>

    <!-- middle "content" column -->
    <div [style.width.px]="contentWidth$ | async"></div>

    <!-- the other column-->
    <div
      *ngIf="authService.isAuthenticated"
      class="flex flex-auto justify-start items-center gap-x-10px flex-grow cursor-pointer"
      (click)="toggleProfileDropdown()"
    >
      <div
        class="flex flex-auto justify-start items-center gap-x-10px flex-grow"
      >
        <!-- Profile Icon -->
        <div>
          <img
            src="../../../../assets/image/mit_evida_profile.svg"
            class="h-full w-auto align-middle"
            alt="profile icon"
          />
        </div>
        <div class="flex flex-col">
          <span class="body-header" title="">{{
            associatedUserName | emptyField
          }}</span>
          <span class="body-small" translate="userProfile.title"></span>
        </div>
      </div>
      <ng-content></ng-content>
    </div>
  </nav>
  <div
    class="absolute left-0 top-60px sm:top-80px h-px bg-petroleum10pct w-full"
  ></div>
</header>
