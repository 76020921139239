import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Input,
} from '@angular/core'
import {
  BehaviorSubject,
  firstValueFrom,
  Observable,
  of,
  Subscription,
} from 'rxjs'
import { MenuItemId } from 'src/app/shared/models/menu.model'
import {
  ProfileInstallation,
  ProfileListItem,
} from 'src/app/shared/services/backend'
import { MenuService } from 'src/app/shared/services/menu.service'
import { ProfileLoginService } from 'src/app/shared/services/profile-login.service'
import { Utils } from 'src/app/utils/utils'
import { AssociatedUsers } from '../../models/associated-users.model'
import { InstallationDetails } from '../../models/installation-details.model'
import { InstallationItem } from '../../models/installation-item.model'
import { PaymentDetails } from '../../models/payment-details.model'
import { PaymentType } from '../../models/payment-type.model'
import { SubscribeTo } from '../../models/subscribe-to.model'
import { PrivateProfileService } from '../../services/private-profile.service'

@Component({
  selector: 'app-private-profile',
  templateUrl: './private-profile.component.html',
  styleUrls: ['./private-profile.component.scss'],
  standalone: false
})
export class PrivateProfileComponent
implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy
{
  @Input() isInToolbar: boolean = false

  private subscription: Subscription = new Subscription()
  private installations = new BehaviorSubject<Array<ProfileListItem> | null>(
    null,
  )
  private associatedUsers = new BehaviorSubject<AssociatedUsers>(null)
  private paymentDetails = new BehaviorSubject<PaymentDetails>(null)
  private installationDetails = new BehaviorSubject<InstallationDetails>(null)
  private supplierName = new BehaviorSubject<string>(null)
  private customerNumber = new BehaviorSubject<string>(null)
  private installationItem: InstallationItem | null = null

  public installations$ = this.installations.asObservable()
  public associatedUsers$ = this.associatedUsers.asObservable()
  public paymentDetails$ = this.paymentDetails.asObservable()
  public installationDetails$ = this.installationDetails.asObservable()
  public supplierName$ = this.supplierName.asObservable()
  public customerNumber$ = this.customerNumber.asObservable()
  public loading$: Observable<boolean> = of(true)

  constructor(
    private profileService: PrivateProfileService,
    private profileLoginService: ProfileLoginService,
    private changeDetectorRef: ChangeDetectorRef,
    public menuService: MenuService,
  ) {}

  public ngOnInit() {
    if (!this.isInToolbar) {
      this.menuService.preselectedMenuItem(MenuItemId.profile)
    }
    this.getInstallations()
  }

  public ngAfterViewInit(): void {
    Utils.scrollToTop()
  }

  public ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges()
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  public onSelectInstallation(installationItem: InstallationItem) {
    // Set customer number and installtion number for selected installation
    this.profileService.setSelectedInstallation({
      customerNumber: installationItem.customerNumber,
      installationNumber:
        installationItem.selectedInstallation.installationNumber,
    })
    this.installationItem = installationItem
    this.getAssociatedUsersInformation(
      installationItem.customerNumber,
      installationItem.selectedInstallation,
    )
  }

  public onAddCustomerRelation() {
    this.profileLoginService.addCustomerRelation()
  }

  public onSubscribeTo(event: SubscribeTo) {
    if (event.type === PaymentType.paymentservice) {
      // Open payment service URL in new tab
      window.open(event.url, '_blank')
    } else {
      if (event?.cpr) {
        this.saveCprNumber(event.cpr, event.type)
      } else {
        if (event.type === PaymentType.easyaccount) {
          this.subscribeToEasyAccount(true)
        } else {
          this.subscribeToDigitalPost(true)
        }
      }
    }
  }

  public onUnsubscribeFrom(type: PaymentType) {
    switch (type) {
    case PaymentType.easyaccount:
      this.subscribeToEasyAccount(false)
      break
    case PaymentType.digitalpost:
      this.subscribeToDigitalPost(false)
      break
    }
  }

  private async subscribeToEasyAccount(subscribe: boolean) {
    this.loading$ = of(true)
    const response = await firstValueFrom(
      this.profileService.subscribeToEasyAccount(
        this.installationItem.customerNumber,
        subscribe,
      ),
    )
    if (response) {
      this.onSelectInstallation(this.installationItem)
    }
  }

  private async subscribeToDigitalPost(subscribe: boolean) {
    this.loading$ = of(true)
    const response = await firstValueFrom(
      this.profileService.subscribeToDigitalPost(
        this.installationItem.customerNumber,
        subscribe,
      ),
    )
    if (response) {
      this.onSelectInstallation(this.installationItem)
    }
  }

  private async saveCprNumber(cpr: string, type: PaymentType) {
    this.loading$ = of(true)
    const response = await firstValueFrom(
      this.profileService.saveCprNumber(
        this.installationItem.customerNumber,
        cpr,
      ),
    )
    if (response) {
      // Convert CPR to bitdthDay to be able to update UI
      const user = this.associatedUsers.getValue()
      user.primary.birthDate = Utils.cprToBirthday(cpr)
      this.associatedUsers.next({
        ...user,
      })
      this.paymentDetails.next({
        ...this.paymentDetails.getValue(),
        birthDate: Utils.cprToBirthday(cpr),
      })

      if (type === PaymentType.easyaccount) {
        this.subscribeToEasyAccount(true)
      } else {
        this.subscribeToDigitalPost(true)
      }
    }
  }

  public reload() {
    window.location.reload()
  }

  private async getInstallations() {
    this.loading$ = of(true)
    const installations = await firstValueFrom(
      this.profileService.getInstallations(),
    )
    this.installations.next(installations)
    this.loading$ = of(false)
  }

  private getAssociatedUsersInformation(
    customerNumber: string,
    installation: ProfileInstallation,
  ) {
    this.loading$ = of(true)
    this.subscription.add(
      this.profileService
        .getAssociatedUsersInformation(
          customerNumber,
          installation.installationNumber,
        )
        .subscribe((response) => {
          if (response) {
            this.customerNumber.next(customerNumber)
            this.associatedUsers.next({
              secondaryName: response.associatedUsers.secondaryName,
              primary: {
                ...response.associatedUsers.primary,
                isBusiness: false,
              },
            })
            this.paymentDetails.next({
              paymentServiceActive: response.paymentServiceActive,
              paymentServiceUrl: response.paymentServiceUrl,
              nemKontoActive: response.nemKontoActive,
              digitalPostActive: response.digitalPostActive,
              birthDate: response.associatedUsers?.primary.birthDate,
              isBusiness: false,
            })
            this.installationDetails.next({
              meterNumber: response.meterNumber,
              gasSupplyStartDate: response.gasSupplyStartDate,
              meteringPoint: response.meteringPoint,
              supplierName: response.supplierName,
              correctorNumber: response.correctorNumber,
              installationNumber: installation.installationNumber,
              displayReadingPlan: installation.displayReadingPlan,
            })
            this.supplierName.next(response.supplierName)
          }
          this.loading$ = of(false)
        }),
    )
  }
}
