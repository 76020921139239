import { Component, Input } from '@angular/core'
import { AuthService } from 'src/app/shared/auth/auth.service'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  standalone: false
})
export class ProfileComponent {
  @Input() isInToolbar: boolean = false

  constructor(public authService: AuthService) {}
}
