import { AfterContentChecked, ChangeDetectorRef, Component, Inject } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Observable, of } from 'rxjs'
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component'
import { PhoneNumber } from 'src/app/shared/components/evida-phone-number/evida-phone-number.component'
import { Patterns } from 'src/app/shared/models/patterns.model'
import { EditBusinessProfilePrimaryContactRequest } from 'src/app/shared/services/backend'
import { LoadingStateService } from 'src/app/shared/services/loading-state.service'
import { BusinessProfileService } from '../../../services/business-profile.service'
import { EditUserUtils } from '../../../utils/edit-user.utils'

@Component({
  selector: 'app-edit-business-user',
  templateUrl: './edit-business-user.component.html',
  styleUrls: ['./edit-business-user.component.scss'],
  standalone: false
})
export class EditBusinessUserComponent implements AfterContentChecked {
  public loading$: Observable<boolean> = of(false)
  /* eslint-disable max-len */
  public prefilledCellPhoneNumber: PhoneNumber = EditUserUtils.formatPhoneNumber(this.data?.cellPhonePrefix, this.data?.formattedCellPhoneNumber)
  public prefilledPhoneNumber: PhoneNumber = EditUserUtils.formatPhoneNumber(this.data?.phonePrefix, this.data?.formattedPhoneNumber)
  public formGroup = new UntypedFormGroup({
    name: new UntypedFormControl({ value: '', disabled: true }),
    email: new UntypedFormControl('', [Validators.pattern(this.patterns.emailPattern)]),
    cell: new UntypedFormControl(this.prefilledCellPhoneNumber),
    phone: new UntypedFormControl(this.prefilledPhoneNumber),
    cvr: new UntypedFormControl({ value: '', disabled: true }),
  })

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private profileService: BusinessProfileService,
    private loadingStateService: LoadingStateService,
    private patterns: Patterns,
    private dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.formGroup.controls.name.setValue(data.name)
      this.formGroup.controls.email.setValue(data.email)
      this.formGroup.controls.cell.setValue(this.prefilledPhoneNumber)
      this.formGroup.controls.phone.setValue(this.prefilledPhoneNumber)
      this.formGroup.controls.cvr.setValue(data.cvr ?? '-')
    }
    this.loadingStateService.dialogRef = this.dialogRef
  }

  public ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges()
  }

  public cancel() {
    this.loadingStateService.dialogRef = null
    this.dialogRef.close(null)
  }

  public submit() {
    if (this.formGroup.valid) {
      this.loading$ = of(true)
      const request = this.contactRequest
      this.profileService.updatePrimaryContact(this.data.customerNumber, request)
        .subscribe(response => {
          if (response) {
            this.loading$ = of(false)
            this.dialogRef.close(request)
          }
        })
    }
  }

  private get contactRequest(): EditBusinessProfilePrimaryContactRequest {
    const { email, cell, phone } = this.formGroup.value

    const request: EditBusinessProfilePrimaryContactRequest = {
      name: this.data.name
    }
    if (EditUserUtils.isValueModified(this.data?.email, email)) {
      request.email = email
    }
    if (EditUserUtils.isValueModified(this.data?.cellPhonePrefix, cell.countryCode)) {
      request.cellPhoneCountryCode = cell.countryCode
    }
    if (EditUserUtils.isValueModified(this.data?.formattedCellPhoneNumber, cell.phoneNumber)) {
      request.cellPhoneNumber = cell.phoneNumber
    }
    if (EditUserUtils.isValueModified(this.data?.phonePrefix, phone.countryCode)) {
      request.phoneCountryCode = phone.countryCode
    }
    if (EditUserUtils.isValueModified(this.data?.formattedPhoneNumber, phone.phoneNumber)) {
      request.phoneNumber = phone.phoneNumber
    }
    return request
  }
}
