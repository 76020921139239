<mat-card appearance="outlined" class="mat-elevation-z1">
  <mat-card-title class="header-small" translate="userProfile.installations.title">
  </mat-card-title>

  <div class="flex flex-col mt-30px mb-30px">

    <app-installation-info *ngIf="installationDetails?.meteringPoint" [text]="'userProfile.installations.meteringPointTooltip' | translate"
      [header]="'userProfile.installations.meteringPoint'" [body]="installationDetails?.meteringPoint">
    </app-installation-info>

    <app-installation-info *ngIf="installationDetails?.installationNumber"
      [header]="'userProfile.installations.installationNumber'" [body]="installationDetails?.installationNumber">
    </app-installation-info>

    <app-installation-info *ngIf="installationDetails?.meterNumber"
      [header]="'userProfile.installations.meterNumber'" [body]="installationDetails?.meterNumber">
    </app-installation-info>

    <app-installation-info *ngIf="installationDetails?.correctorNumber"
      [header]="'userProfile.installations.correctorNumber'" [body]="installationDetails?.correctorNumber">
    </app-installation-info>

    <app-installation-info *ngIf="installationDetails?.gasSupplyStartDate"
      [header]="'userProfile.installations.gasSupplyEstablished'"
      [body]="'userProfile.installations.gasSupplyEstablishedValue' | translate: { date: installationDetails?.gasSupplyStartDate | date : 'dd. MMMM YYYY' } ">
    </app-installation-info>

    <app-installation-info *ngIf="installationDetails?.displayReadingPlan"
      [header]="'userProfile.installations.gasMeterReading'" [body]="installationDetails?.displayReadingPlan">
    </app-installation-info>

    <!-- Add supplierName if needed -->
    <!-- <app-installation-info [header]="'userProfile.installations.supplierName'"
      [body]="installationDetails?.supplierName">
    </app-installation-info> -->

    <app-installation-info [header]="'userProfile.installations.gasLines'"
      [htmlBody]="'userProfile.installations.info'">
    </app-installation-info>
  </div>
</mat-card>