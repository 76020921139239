<div class="flex flex-col">
  <div *ngIf="title" class="body-header" [translate]="title"></div>
  <div *ngIf="subtitle" class="body-copy"
    [translate]="hasInvoiceViaEmail ? 'userProfile.paymentDetails.businessInvoice.sigendSubtitle' : subtitle"></div>

  <!-- Handle subscribe/unsubscribe buttons -->
  <div class="flex justify-between mt-30px">
    <div class="subscribed flex flex-col" *ngIf="hasInvoiceViaEmail">
      <span class="label body-header" translate="userProfile.paymentDetails.businessInvoice.email"></span>
      <span class="body-copy">{{ invoiceViaEmail }}</span>
    </div>
    <app-evida-button *ngIf="subscribeButton && !hasInvoiceViaEmail" class="button" type="primary"
      (clicked)="onEditInvoiceEmailForm(false)">
      {{ subscribeButton | translate }}
    </app-evida-button>

    <div *ngIf="hasInvoiceViaEmail" class="edit-button" (click)="onEditInvoiceEmailForm(true)">
      <mat-icon svgIcon="evida_edit" inline="true"></mat-icon>
    </div>
  </div>
</div>