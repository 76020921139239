import { Component, Input } from '@angular/core'
import { AddressMapper } from 'src/app/soft-login/mappers/AddressMapper'
import { Address, ProfileInstallation } from '../../services/backend'

@Component({
  selector: 'app-selected-installation-cell',
  templateUrl: './selected-installation-cell.component.html',
  styleUrls: ['./selected-installation-cell.component.scss'],
  standalone: false,
})
export class SelectedInstallationCellComponent {
  @Input() public installation: ProfileInstallation
  @Input() public smallSize = false
  @Input() public showMeterInfo = true
  @Input() public customerNumber: string

  public formatAddress(address: Address): string {
    return AddressMapper.formatAddress(address)
  }

  public get meterInfo(): boolean {
    return !!(this.installation.meterUnits || this.installation.meterNumber)
  }
}
