<app-loader *ngIf="loading$ | async"></app-loader>
<div class="flex flex-col">
    <h1 class="mb-30px header-medium" translate="userProfile.associatedUsers.editUserTitle"></h1>
    <div class="flex flex-col">
        <form [formGroup]="formGroup">
            <div class="flex flex-col">
                <!-- Name -->
                <div class="flex flex-col mb-30px">
                    <span class="body-header" translate="userProfile.associatedUsers.name"></span>
                    <input class="body-copy border-none bg-transparent" formControlName="name">
                </div>

                <!-- Email -->
                <app-form-input class="flex flex-col" title="userProfile.associatedUsers.email"
                    placeholder="userProfile.associatedUsers.emailPlaceholder" [errorLookup]="{
                         pattern: 'shared.errormessages.emailInvalid' | translate,
                         incorrect: 'shared.errormessages.emailInvalid' | translate
                        }" [control]="formGroup.controls.email">
                </app-form-input>

                <!-- Cell phone -->
                <app-evida-phone-number class="mb-10px" [required]="false"
                    label="userProfile.associatedUsers.mobilnummer"
                    phoneNumberPlaceholder="userProfile.associatedUsers.mobilnummerPlaceholder" formControlName="cell"
                    [value]="prefilledCellPhoneNumber">
                </app-evida-phone-number>

                <!-- Phone number -->
                <app-evida-phone-number class="mb-10px" [required]="false"
                    label="userProfile.associatedUsers.phonenummer"
                    phoneNumberPlaceholder="userProfile.associatedUsers.phonenummerPlaceholder" formControlName="phone"
                    [value]="prefilledPhoneNumber">
                </app-evida-phone-number>

                <!-- Cvr -->
                <div class="flex flex-col">
                    <span class="body-header" translate="userProfile.associatedUsers.cvr"></span>
                    <input class="body-copy border-none bg-transparent" formControlName="cvr">
                </div>
            </div>
        </form>
    </div>

    <div class="flex justify-between mt-30px">
        <app-evida-button class="button" type="secondary" (clicked)="cancel()">
            {{ 'userProfile.associatedUsers.cancelButton' | translate }}
        </app-evida-button>
        <app-evida-button class="button" type="primary" (clicked)="submit()" [disabled]="!formGroup.valid">
            {{ 'userProfile.associatedUsers.submitButton' | translate }}
        </app-evida-button>
    </div>
</div>