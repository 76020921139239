import { Component, Input } from '@angular/core'

export enum BannerTheme {
  'default' = 'default',
  'green' = 'green',
}

@Component({
  selector: 'app-evida-banner',
  templateUrl: './evida-banner.component.html',
  styleUrls: ['./evida-banner.component.scss'],
  standalone: false
})
export class EvidaBannerComponent {
  @Input() public icon = 'evida_illustration_edit'
  @Input() public noIcon = false
  @Input() public theme: BannerTheme = BannerTheme.default

  public get themeClass() {
    return `evida-banner--${this.theme}`
  }
}
