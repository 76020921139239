import { Component, Input } from '@angular/core'
import { InstallationDetails } from '../../models/installation-details.model'

@Component({
  selector: 'app-installations',
  templateUrl: './installations.component.html',
  styleUrls: ['./installations.component.scss'],
  standalone: false
})
export class InstallationsComponent {
  @Input() public installationDetails: InstallationDetails
}
