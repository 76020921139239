import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core'
import { Menu, SubmenuItem } from '../../models/menu.model'
import { MenuService } from '../../services/menu.service'

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  standalone: false
})

export class VerticalMenuComponent implements AfterContentChecked {
  @Input() public burgerMenu = false
  @Output() public navigateToRoute = new EventEmitter<string>()

  constructor(
    public menuService: MenuService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  public ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges()
  }

  public onMenuClick(menuItem: Menu) {
    this.menuService.menuItemSelected(menuItem)
    this.navigateTo(menuItem.routerLink)
  }

  public onSubmenuClick(menuItem: SubmenuItem) {
    this.menuService.submenuItemSelected(menuItem)
    this.navigateTo(menuItem.routerLink)
  }

  private navigateTo(routerLink: string) {
    if (routerLink) {
      this.navigateToRoute.emit(routerLink)
    }
  }
}
