import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Dialog } from '../../models/dialog.model'

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  standalone: false
})

export class DialogComponent {

  constructor(
        public dialogRef: MatDialogRef<DialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Dialog
  ) {
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result === Action.primary && this.data.primaryButtonAction) {
          this.data.primaryButtonAction()
        } else if (result === Action.secondary && this.data.secondaryButtonAction) {
          this.data.secondaryButtonAction()
        }
      } else {
        // Clicked on backdrop
        if (this.data.cancelAction) {
          this.data.cancelAction()
        }
      }
    })
  }

  public primaryButtonClick() {
    this.dialogRef.close(Action.primary)
  }

  public secondaryButtonClick() {
    this.dialogRef.close(Action.secondary)
  }
}

export enum Action {
    primary = 'primary',
    secondary = 'secondary',
}
