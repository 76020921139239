/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { CardType } from '../model/models';
import { MonthlyReadingsResponse } from '../model/models';
import { MonthlyReadingsValidationRequest } from '../model/models';
import { MonthlyReadingsValidationResponse } from '../model/models';
import { MonthlyReadingsWithDiscrepancyResponse } from '../model/models';
import { ValidationErrorResponse } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class MonthlyReadingService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param accountNumber 
     * @param readingCardNumber 
     * @param cardType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMonthlyReadingGet(accountNumber: string, readingCardNumber: string, cardType: CardType, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<MonthlyReadingsResponse>;
    public apiMonthlyReadingGet(accountNumber: string, readingCardNumber: string, cardType: CardType, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<MonthlyReadingsResponse>>;
    public apiMonthlyReadingGet(accountNumber: string, readingCardNumber: string, cardType: CardType, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<MonthlyReadingsResponse>>;
    public apiMonthlyReadingGet(accountNumber: string, readingCardNumber: string, cardType: CardType, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (accountNumber === null || accountNumber === undefined) {
            throw new Error('Required parameter accountNumber was null or undefined when calling apiMonthlyReadingGet.');
        }
        if (readingCardNumber === null || readingCardNumber === undefined) {
            throw new Error('Required parameter readingCardNumber was null or undefined when calling apiMonthlyReadingGet.');
        }
        if (cardType === null || cardType === undefined) {
            throw new Error('Required parameter cardType was null or undefined when calling apiMonthlyReadingGet.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (accountNumber !== undefined && accountNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>accountNumber, 'accountNumber');
        }
        if (readingCardNumber !== undefined && readingCardNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>readingCardNumber, 'readingCardNumber');
        }
        if (cardType !== undefined && cardType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cardType, 'cardType');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<MonthlyReadingsResponse>(`${this.configuration.basePath}/api/MonthlyReading`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param readingId 
     * @param receiptId 
     * @param cardData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMonthlyReadingReadingIdReceiptReceiptIdGet(readingId: string, receiptId: string, cardData?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public apiMonthlyReadingReadingIdReceiptReceiptIdGet(readingId: string, receiptId: string, cardData?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public apiMonthlyReadingReadingIdReceiptReceiptIdGet(readingId: string, receiptId: string, cardData?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public apiMonthlyReadingReadingIdReceiptReceiptIdGet(readingId: string, receiptId: string, cardData?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (readingId === null || readingId === undefined) {
            throw new Error('Required parameter readingId was null or undefined when calling apiMonthlyReadingReadingIdReceiptReceiptIdGet.');
        }
        if (receiptId === null || receiptId === undefined) {
            throw new Error('Required parameter receiptId was null or undefined when calling apiMonthlyReadingReadingIdReceiptReceiptIdGet.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (cardData !== undefined && cardData !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cardData, 'cardData');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/api/MonthlyReading/${encodeURIComponent(String(readingId))}/receipt/${encodeURIComponent(String(receiptId))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Submits a reading that must not violate expectations.  This call will respond with BadRequest if readings result in discrepancies.
     * @param readingId Id provided from GET
     * @param monthlyReadingsValidationRequest Model for validation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMonthlyReadingReadingIdSubmitReadingPost(readingId: string, monthlyReadingsValidationRequest?: MonthlyReadingsValidationRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<MonthlyReadingsValidationResponse>;
    public apiMonthlyReadingReadingIdSubmitReadingPost(readingId: string, monthlyReadingsValidationRequest?: MonthlyReadingsValidationRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<MonthlyReadingsValidationResponse>>;
    public apiMonthlyReadingReadingIdSubmitReadingPost(readingId: string, monthlyReadingsValidationRequest?: MonthlyReadingsValidationRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<MonthlyReadingsValidationResponse>>;
    public apiMonthlyReadingReadingIdSubmitReadingPost(readingId: string, monthlyReadingsValidationRequest?: MonthlyReadingsValidationRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (readingId === null || readingId === undefined) {
            throw new Error('Required parameter readingId was null or undefined when calling apiMonthlyReadingReadingIdSubmitReadingPost.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<MonthlyReadingsValidationResponse>(`${this.configuration.basePath}/api/MonthlyReading/${encodeURIComponent(String(readingId))}/submit-reading`,
            monthlyReadingsValidationRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Submits a reading where discrepancies are allowed
     * @param readingId Id provided from GET
     * @param monthlyReadingsValidationRequest Model for validation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMonthlyReadingReadingIdSubmitReadingWithDiscrepancyPost(readingId: string, monthlyReadingsValidationRequest?: MonthlyReadingsValidationRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<MonthlyReadingsWithDiscrepancyResponse>;
    public apiMonthlyReadingReadingIdSubmitReadingWithDiscrepancyPost(readingId: string, monthlyReadingsValidationRequest?: MonthlyReadingsValidationRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<MonthlyReadingsWithDiscrepancyResponse>>;
    public apiMonthlyReadingReadingIdSubmitReadingWithDiscrepancyPost(readingId: string, monthlyReadingsValidationRequest?: MonthlyReadingsValidationRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<MonthlyReadingsWithDiscrepancyResponse>>;
    public apiMonthlyReadingReadingIdSubmitReadingWithDiscrepancyPost(readingId: string, monthlyReadingsValidationRequest?: MonthlyReadingsValidationRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (readingId === null || readingId === undefined) {
            throw new Error('Required parameter readingId was null or undefined when calling apiMonthlyReadingReadingIdSubmitReadingWithDiscrepancyPost.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<MonthlyReadingsWithDiscrepancyResponse>(`${this.configuration.basePath}/api/MonthlyReading/${encodeURIComponent(String(readingId))}/submit-reading-with-discrepancy`,
            monthlyReadingsValidationRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
