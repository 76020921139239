import { LoginRoutes } from 'src/app/login/models/login-routes.model'

export const EvidaRoutes = {
  evida: 'evida',
  frontpage: 'frontpage',
  invoice: 'invoice',
  consumption: {
    root: 'consumption',
    development: 'development',
    timedata: 'timedata'
  },
  productionreadings: 'production-readings',
  followconsumption: 'follow-consumption',
  profile: 'profile',
}

export const EvidaFullRoutes = {
  frontpage: EvidaRoutes.evida,
  invoice: `${EvidaRoutes.evida}/${EvidaRoutes.invoice}`,
  timedata: `${EvidaRoutes.evida}/${EvidaRoutes.consumption.root}/${EvidaRoutes.consumption.timedata}`,
  development: `${EvidaRoutes.evida}/${EvidaRoutes.consumption.root}/${EvidaRoutes.consumption.development}`,
  productionreadings: `${EvidaRoutes.evida}/${EvidaRoutes.productionreadings}`,
  followconsumption: `${EvidaRoutes.evida}/${EvidaRoutes.followconsumption}`,
  profile: `${EvidaRoutes.evida}/${EvidaRoutes.profile}`,
  logout: `/${LoginRoutes.login}/${LoginRoutes.logout}`
}
