import { Component, HostBinding, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core'
import { ControlValueAccessor, NgControl } from '@angular/forms'
import { MatFormFieldControl } from '@angular/material/form-field'
import { Subject } from 'rxjs'

@Component({
  selector: 'app-evida-checkbox',
  templateUrl: './evida-checkbox.component.html',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: EvidaCheckboxComponent,
    }
  ],
  standalone: false
})
export class EvidaCheckboxComponent implements ControlValueAccessor, OnDestroy, OnInit {
  static nextId = 0
  @HostBinding() public id = `evida-checkbox-${EvidaCheckboxComponent.nextId++}`
  @Input() public startingError = false

  public disabled = false
  public errorState = false
  public controlType = 'checkbox'
  public autofilled = undefined
  public userAriaDescribedBy = undefined
  public stateChanges = new Subject<void>()
  public placeholder = ''
  public focused = false
  public empty: boolean
  public shouldLabelFloat: boolean

  private _required = false
  private internalValue: boolean
  // variables that will hold the callbacks passed from ControlValueAccessor interface
  private onTouchedCallback: () => void = EvidaCheckboxComponent.doNothing
  private onChangeCallback: (_: any) => void = EvidaCheckboxComponent.doNothing

  constructor(@Optional() @Self() public ngControl: NgControl,) {
    // Replace the provider from above with this.
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this
    }
  }

  public get value(): boolean {
    return this.internalValue
  }
  public set value(v: boolean) {
    if (v !== this.internalValue) {
      this.internalValue = v
      this.stateChanges.next()
      this.onChangeCallback(v)
    }
    this.errorState = !this.ngControl.valid
  }

  
  get required() {
    return this._required
  }
  
  @Input() set required(req) {
    this._required = !!req
    this.stateChanges.next()
  }

  private static readonly doNothing = () => { }

  public onBlur(): void {
    this.onTouchedCallback()
  }

  // From ControlValueAccessor interface
  public writeValue(value: any): void {
    if (value !== this.internalValue) {
      this.internalValue = value
    }
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn
  }

  public ngOnInit() {
    if (this.startingError) {
      this.errorState = Boolean(this.startingError)
    }
    else {
      this.errorState = false
    }
  }
  public ngOnDestroy() {
    this.stateChanges.complete()
  }
}
