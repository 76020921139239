import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { EvidaScreens } from 'src/app/tracking/evida-screens'
import { GoogleAnalytics } from 'src/app/tracking/google-analytics'
import { Logger } from 'src/app/utils/logger'

@Component({
  selector: 'app-site-busy',
  templateUrl: './site-busy-component.html',
  styleUrls: ['./site-busy-component.scss'],
  standalone: false
})
export class SiteBusyComponent {
  constructor(private router: Router, private logger: Logger) {
    this.trackErrorBusy()
  }
  private trackErrorBusy() {
    GoogleAnalytics.trackScreen(EvidaScreens.Error.busy)
  }
}
