import { Pipe, PipeTransform } from '@angular/core'
import { Utils } from 'src/app/utils/utils'
import { CardUnitType } from '../models/card-type.model'

@Pipe({
  name: 'formatMonthlyValue',
  standalone: false
})
export class FormatUnitPipe implements PipeTransform {

  private readonly kwhUnits = [CardUnitType.eprod, CardUnitType.vprod].map(it => it.toString())

  transform(value: number | null, unit: string): string {
    if (!value) return ''
    const [text, subindex] = Utils.extractSubindex(unit)
    const lowerCaseUnit = unit.toLowerCase()
    const isKWh = this.kwhUnits.includes(lowerCaseUnit)
    const unitHTML = isKWh ? 'kWh' : `${text}<sup>${subindex}</sup>`
    return `${value} ${unitHTML}`
  }
}
