<mat-card appearance="outlined" class="mat-elevation-z1">
    <mat-card-title class="header-small" translate="userProfile.associatedUsers.title"></mat-card-title>

    <div class="flex flex-col mt-30px mb-30px">
        <app-associated-user title="userProfile.associatedUsers.primaryUser" [associatedUser]="associatedUsers?.primary"
            [divider]="associatedUsers?.secondaryName" (editUserForm)="onEditUserForm($event)">
        </app-associated-user>

        <app-associated-user *ngIf="associatedUsers?.secondaryName" title="userProfile.associatedUsers.secondaryUser"
            [secondaryName]="associatedUsers?.secondaryName" (editUserForm)="onEditUserForm($event)">
        </app-associated-user>
    </div>
</mat-card>