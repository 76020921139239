import { Component, EventEmitter, Input, Output, inject } from '@angular/core'
import { Features } from 'src/app/shared/models/features.model'
import { EanStatus } from 'src/app/shared/services/backend'
import { PaymentDetails } from '../../../models/payment-details.model'
import { PaymentType } from '../../../models/payment-type.model'

@Component({
  selector: 'app-business-payment-details',
  templateUrl: './business-payment-details.component.html',
  styleUrls: ['./business-payment-details.component.scss'],
  standalone: false
})
export class BusinessPaymentDetailsComponent {
  @Input() public paymentDetails: PaymentDetails
  @Input() public supplierName: string | null
  @Input() public eanStatus: EanStatus |null
  @Output() public subscribe = new EventEmitter<string>()
  @Output() public updateInvoiceEmail = new EventEmitter<string>()
  @Output() public updateEanInvoice = new EventEmitter<void>()

  public features = inject(Features)
  public paymentType = PaymentType

  public onSubscribePaymentService(url: string) {
    this.subscribe.emit(url)
  }
}
