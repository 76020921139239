import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core'

enum Directions {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right'
}

@Directive({
  selector: '[appTooltip]',
  standalone: false
})

export class TooltipDirective implements OnDestroy {
  @Input() public appTooltip = ''
  private myPopup = null
  private timer

  @HostListener('window:scroll')
  onWindowScroll() {
    this.ngOnDestroy()
  }

  constructor(private elementRef: ElementRef) { }

  public ngOnDestroy(): void {
    if (this.myPopup) {
      this.myPopup.remove()
    }
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.timer = setTimeout(() => {
      this.createTooltipPopup(this.elementRef.nativeElement.getBoundingClientRect())
    }, 100)
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    if (this.myPopup) {
      this.myPopup.remove()
      this.myPopup = null
    }
  }

  private createTooltipPopup(rect: any) {
    if (this.myPopup !== null) {
      return
    }
    // Parrent button position/size
    const x = rect.left
    const y = rect.top
    const width = rect.width
    const height = rect.height

    // Make tooltip
    const popup = document.createElement('div')
    popup.innerHTML = this.appTooltip
    popup.setAttribute('class', 'tooltip-container')
    document.body.appendChild(popup)

    const pageMargin = 10
    const popupWidth = popup.getBoundingClientRect().width
    const popupHeight = popup.getBoundingClientRect().height

    let xPos = 0
    let yPos = 0
    let direction = Directions.left

    const canShowOnLeft = (x - popupWidth) > pageMargin
    const canShowOnLeftTop = ((y + height / 2) - (popupHeight / 2) > pageMargin)
    const canShowOnLeftBottom = window.innerHeight - ((y + height / 2) + (popupHeight / 2)) > pageMargin

    const canShowOnRight = (window.innerWidth - (x + rect.width) > (popupWidth + pageMargin))
    const canShowOnRightTop = ((y + height / 2) - (popupHeight / 2) > pageMargin)
    const canShowOnRightBottom = window.innerHeight - ((y + height / 2) + (popupHeight / 2)) > pageMargin

    const canShowOnTop = (y - popupHeight) > pageMargin
    const canShowOnTopLeft = canShowOnLeft
    const canShowOnTopRight = canShowOnRight

    const canShowOnBottom = (window.innerHeight - (y + rect.height) > (popupHeight + pageMargin))
    const canShowOnBottomLeft = canShowOnLeft
    const canShowOnBottomRight = canShowOnRight

    const canShowOnLeftSafe = canShowOnLeft && canShowOnLeftTop && canShowOnLeftBottom
    const canShowOnRightSafe = canShowOnRight && canShowOnRightTop && canShowOnRightBottom
    const canShowOnTopSafe = canShowOnTop && canShowOnTopLeft && canShowOnTopRight
    const canShowOnBottomSafe = canShowOnBottom && canShowOnBottomLeft && canShowOnBottomRight

    if (
      !canShowOnLeftSafe &&
      !canShowOnRightSafe &&
      !canShowOnTopSafe &&
      !canShowOnBottomSafe
    ) {
      // Calculate best guess
      if (y - (window.innerHeight / 2) > (x - (window.innerWidth / 2))) {
        direction = window.innerHeight - y > y ? Directions.bottom : Directions.top
      } else {
        direction = window.innerWidth - x > x ? Directions.right : Directions.left
      }
    } else {
      if (canShowOnRightSafe) {
        direction = Directions.right
      } else if (canShowOnLeftSafe) {
        direction = Directions.left
      } else if (!canShowOnLeftSafe && !canShowOnRightSafe) {
        if (canShowOnTopSafe) {
          direction = Directions.top
        } else if (canShowOnBottomSafe) {
          direction = Directions.bottom
        }
      }
    }

    popup.setAttribute('class', `tooltip-container ${direction}`)
    const arrowOffset = 5
    const halfWidth = (width / 2) + arrowOffset
    const halfHeight = (height / 2) + arrowOffset

    switch (direction) {
    case Directions.left:
      xPos = (x - popupWidth) - halfWidth
      yPos = (y + (height / 2)) - popupHeight / 2
      break
    case Directions.right:
      xPos = (x + width) + halfWidth
      yPos = (y + (height / 2)) - popupHeight / 2
      break
    case Directions.top:
      xPos = x + (width / 2) - popupWidth / 2
      yPos = (y - popupHeight) - halfHeight
      break
    case Directions.bottom:
      xPos = x + (width / 2) - popupWidth / 2
      yPos = (y + height) + halfHeight
      break
    }

    popup.style.top = yPos.toString() + 'px'
    popup.style.left = xPos.toString() + 'px'
    this.myPopup = popup
  }
}
