import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-add-customer-relation',
  templateUrl: './add-customer-relation.component.html',
  styleUrls: ['./add-customer-relation.component.scss'],
  standalone: false,
})
export class AddCustomerRelationComponent {
  @Input() isBusiness = false
  @Output() addCustomerRelation = new EventEmitter<void>()

  onAddCustomerRelation() {
    this.addCustomerRelation.emit()
  }
}
