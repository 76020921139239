<!-- If contract is open - check for meter info -->
<ng-container *ngIf="!installation.contract?.isClosed">
  <div class="flex flex-col w-full bottom-border"></div>
</ng-container>

<!-- Always show address if found -->
<span
  *ngIf="installation.address && showAddress"
  class="flex flex-col body-copy whitespace-pre-line"
  [ngClass]="{ 'small-font-size': smallSize }"
  >{{ formatAddress(installation.address) }}
</span>

<span
  *ngIf="meterInfo && showMeterInfo"
  class="flex flex-col body-copy"
  [ngClass]="{ 'small-font-size': smallSize }"
  translate="userProfile.customerRelations.meterInfo"
  [translateParams]="{
    meterInfo: this.installation.meterUnits || this.installation.meterNumber,
  }"
>
</span>

<!-- Meter info removed -->
<strong
  *ngIf="!meterInfo && showMeterInfo"
  class="flex flex-col text-green"
  [ngClass]="{ 'small-font-size': smallSize }"
  translate="customerRelations.removed"
>
</strong>

<!-- If contract is closed -->
<strong
  *ngIf="installation.contract?.isClosed"
  class="flex flex-col whitespace-pre-line text-green"
  [ngClass]="{ 'small-font-size': smallSize }"
  translate="customerRelations.closedContract"
>
</strong>
